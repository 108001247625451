import React, { useContext } from "react";

import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useJwt } from "react-jwt";

/// Image
import profile from "../../../assets/images/profile/education/pic1.jpg";
// import avatar from "../../../assets/images/avatar/1.jpg";

import { ThemeContext } from "../../../context/ThemeContext";
import Logout from "../nav/Logout";
import { IMAGES } from "../../constant/theme";

export const IMG_URL = "https://ocs-media.fra1.cdn.digitaloceanspaces.com/";
const Header = ({ onNote }) => {
  const { background, changeBackground } = useContext(ThemeContext);
  const handleThemeMode = () => {
    if (background.value === "dark") {
      changeBackground({ value: "light", label: "Light" });
    } else {
      changeBackground({ value: "dark", label: "Dark" });
    }
  };
  const token = localStorage.getItem("token");
  const { decodedToken, isExpired } = useJwt(token);

  return (
    <>
      <div className="header">
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              {/* <div className="header-left"></div> */}
              <ul className="navbar-nav header-right ms-auto">
                <li className="nav-item dropdown notification_dropdown">
                  <Link
                    to={"#"}
                    className={`nav-link bell dlab-theme-mode p-0 ${
                      background.value === "dark" ? "active" : ""
                    }`}
                    onClick={() => handleThemeMode()}>
                    <i
                      id="icon-light"
                      className="fas fa-sun"></i>
                    <i
                      id="icon-dark"
                      className="fas fa-moon"></i>
                  </Link>
                </li>
                {/* <Dropdown
                  as="li"
                  className="nav-item dropdown notification_dropdown">
                  <Dropdown.Toggle
                    className="nav-link i-false c-pointer icon-bell-effect"
                    variant=""
                    as="a">
                    <svg
                      id="icon-user"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-bell">
                      <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                      <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                    </svg>
                    <div className="pulse-css"></div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    align="end"
                    className="mt-3 dropdown-menu dropdown-menu-right">
                    <ul className="list-unstyled">
                      <li className="media dropdown-item align-items-center gap-3">
                        <span className="success">
                          <i className="ti-user"></i>
                        </span>
                        <div className="media-body">
                          <Link to={"#"}>
                            <p>
                              <strong>Martin</strong> has added a{" "}
                              <strong>customer</strong> Successfully
                            </p>
                          </Link>
                        </div>
                        <span className="notify-time">3:20 am</span>
                      </li>
                      <li className="media dropdown-item align-items-center gap-3">
                        <span className="primary">
                          <i className="ti-shopping-cart"></i>
                        </span>
                        <div className="media-body">
                          <Link to={"#"}>
                            <p>
                              <strong>Jennifer</strong> purchased Light
                              Dashboard 2.0.
                            </p>
                          </Link>
                        </div>
                        <span className="notify-time">3:20 am</span>
                      </li>
                      <li className="media dropdown-item align-items-center gap-3">
                        <span className="danger">
                          <i className="ti-bookmark"></i>
                        </span>
                        <div className="media-body">
                          <Link to={"#"}>
                            <p>
                              <strong>Robin</strong> marked a{" "}
                              <strong>ticket</strong> as unsolved.
                            </p>
                          </Link>
                        </div>
                        <span className="notify-time">3:20 am</span>
                      </li>
                      <li className="media dropdown-item align-items-center gap-3">
                        <span className="primary">
                          <i className="ti-heart"></i>
                        </span>
                        <div className="media-body">
                          <Link to={"#"}>
                            <p>
                              <strong>David</strong> purchased Light Dashboard
                              1.0.
                            </p>
                          </Link>
                        </div>
                        <span className="notify-time">3:20 am</span>
                      </li>
                      <li className="media dropdown-item align-items-center gap-3">
                        <span className="success">
                          <i className="ti-image"></i>
                        </span>
                        <div className="media-body">
                          <Link to={"#"}>
                            <p>
                              <strong> James.</strong> has added a
                              <strong>customer</strong> Successfully
                            </p>
                          </Link>
                        </div>
                        <span className="notify-time">3:20 am</span>
                      </li>
                    </ul>
                    <Link
                      className="all-notification"
                      to="#">
                      See all notifications <i className="ti-arrow-right" />
                    </Link>
                  </Dropdown.Menu>
                </Dropdown> */}
                <Dropdown
                  as="li"
                  className="nav-item header-profile">
                  <Dropdown.Toggle
                    to={"#"}
                    className="nav-link i-false cursor-pointer"
                    as="div">
                    <img
                      src={
                        IMG_URL +
                        "LMST/ADMIN/IMAGES/PROFILE/" +
                        decodedToken?.image
                      }
                      width="20"
                      alt="Profile"
                      onError={(e) => {
                        e.target.src = IMAGES.Avatpng1;
                      }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    align="end"
                    className="mt-3 dropdown-menu dropdown-menu-right ">
                    {/* <Link
                      to={"/app-profile"}
                      className="dropdown-item ai-icon icon-bell-effect">
                      <svg
                        id="icon-user1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-user">
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                        <circle
                          cx="12"
                          cy="7"
                          r="4"></circle>
                      </svg>
                      <span className="ms-2">Profile </span>
                    </Link> */}
                    <Logout />
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
