// import axiosInstance from "../services/AxiosInstance";

// export async function CheckEmailPhone(email, phone) {
//   return axiosInstance
//     .get(`/admin-api/users/check-email-phone?phone=${phone}&email=${email}`)
//     .then((response) => response.data)
//     .catch((error) => {
//       console.error("Error fetching users check:", error);
//       throw error;
//     });
// }

import axiosInstance from "../services/AxiosInstance";

export async function CheckEmailPhone(email = "", phone = "") {
  const params = new URLSearchParams();
  if (email) params.append("email", email);
  if (phone) params.append("phone", phone);

  return axiosInstance
    .get(`/admin-api/users/check-email-phone?${params.toString()}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching users check:", error);
      throw error;
    });
}
