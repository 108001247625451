import React from "react";
import { Link, useNavigate } from "react-router-dom";

const PageTitle = ({ motherMenu, activeMenu, pageContent, userId, goBack }) => {
  const navigate = useNavigate();
  return (
    <div className="row page-titles mx-0">
      <div className="col-sm-6 p-md-0">
        {goBack ? (
          <div
            className="welcome-text d-flex align-items-center gap-1 cursor-pointer"
            onClick={() => goBack && navigate(-1)}>
            {goBack && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                stroke="#1367c8"
                fill="#1367c8"
                class="bi bi-chevron-double-left"
                viewBox="0 0 16 16">
                <path
                  fill-rule="evenodd"
                  d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                />
                <path
                  fill-rule="evenodd"
                  d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                />
              </svg>
            )}
            <h4>{activeMenu}</h4>
          </div>
        ) : (
          <div className="welcome-text d-flex align-items-center gap-1">
            <h4>{activeMenu}</h4>
          </div>
        )}
      </div>
      <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={"#"}>{motherMenu}</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={"#"}>{activeMenu}</Link>
          </li>
          {userId && (
            <li className="breadcrumb-item active">
              <Link to={"#"}>{userId}</Link>
            </li>
          )}
        </ol>
      </div>
    </div>
  );
};

export default PageTitle;
