// admin-api/dashboard

import axiosInstance from "../../services/AxiosInstance";

export async function GetTransactions(idStart, date, type, status, page, size) {
  const params = {
    ...(idStart && { idStart }),
    ...(date && { date }),
    ...(type && { type }),
    ...(status && { status }),
    page,
    size,
  };
  let url = "admin-api/transactions";
  return axiosInstance
    .get(url, { params })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching community dashboard data:", error);
      throw error;
    });
}
