import React, { useReducer } from "react";
import { Modal, Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

import PageTitle from "../../layouts/PageTitle";
import { IMAGES } from "../../constant/theme";

const initialState = false;
const reducer = (state, action) => {
  switch (action.type) {
    case "sendMessage":
      return { ...state, sendMessage: !state.sendMessage };
    case "postModal":
      return { ...state, post: !state.post };
    case "linkModal":
      return { ...state, link: !state.link };
    case "cameraModal":
      return { ...state, camera: !state.camera };
    case "replyModal":
      return { ...state, reply: !state.reply };
    default:
      return state;
  }
};

function AdminProfile({ data }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <>
      <div className="row">
        <div className=" col-lg-12">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="profile-tab">
                    <div className="custom-tab-1">
                      <Tab.Container defaultActiveKey={"Personal"}>
                        <Nav
                          as="ul"
                          className="nav nav-tabs">
                          <Nav.Item as="li">
                            <Nav.Link eventKey="Personal">Personal</Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <Tab.Content className="tab-content">
                          <Tab.Pane eventKey={"Personal"}>
                            <div className="d-flex justify-content-between flex-md-row flex-column gap-xsm-1 gap-md-4 gap-3">
                              <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12 pt-4 ">
                                <div className="">
                                  <div className="card overflow-hidden">
                                    <div
                                      className="text-center p-3 overlay-box"
                                      // style={{
                                      //   backgroundImage: `url(${IMAGES.BigImg1})`,
                                      // }}
                                    >
                                      <div className="profile-photo">
                                        <img
                                          src={"url/" + data.image}
                                          width="100"
                                          className="img-fluid rounded-circle"
                                          alt=""
                                          onError={(e) => {
                                            e.target.src = IMAGES.Avatpng1;
                                          }}
                                        />
                                      </div>
                                      <p className="text-white mb-0">
                                        {data.firstName + " " + data.lastName}
                                      </p>
                                    </div>
                                    <ul className="list-group list-group-flush bg-light">
                                      <li className="list-group-item d-flex justify-content-between border-dark  py-3 ">
                                        <span className="mb-1">
                                          {data.firstName + " " + data.lastName}
                                        </span>
                                      </li>
                                      <li className="list-group-item d-flex justify-content-between border-dark  py-3">
                                        <span className="mb-1">#{data.id}</span>
                                      </li>
                                      <li className="list-group-item d-flex justify-content-between border-dark  py-3">
                                        <span className="mb-1">
                                          {data.email}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-9 col-lg-7 col-md-7 col-sm-12 mt-4 bg-light rounded p-4">
                                <div className="profile-personal-info">
                                  <h4 className="text-primary mb-4">
                                    About {data.firstName + " " + data.lastName}
                                  </h4>

                                  <div className="row mb-4">
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                      <h5 className="f-w-500">
                                        Name
                                        <span className="pull-right">:</span>
                                      </h5>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                                      <span>
                                        {data.firstName + " " + data.lastName}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="row mb-4">
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                      <h5 className="f-w-500">
                                        email
                                        <span className="pull-right">:</span>
                                      </h5>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                                      <span>{data.email}</span>
                                    </div>
                                  </div>
                                  <div className="row mb-4">
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                      <h5 className="f-w-500">
                                        Phone Number
                                        <span className="pull-right">:</span>
                                      </h5>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                                      <span>{data.phone}</span>
                                    </div>
                                  </div>
                                  <div className="row mb-4">
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                      <h5 className="f-w-500">
                                        Language
                                        <span className="pull-right">:</span>
                                      </h5>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                                      <span>{data.language}</span>
                                    </div>
                                  </div>
                                  <div className="row mb-4">
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                      <h5 className="f-w-500">
                                        Status
                                        <span className="pull-right">:</span>
                                      </h5>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                                      <span>
                                        {data.verified
                                          ? "Verified"
                                          : "Not Verified"}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="row mb-4">
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                      <h5 className="f-w-500">
                                        Register Date
                                        <span className="pull-right">:</span>
                                      </h5>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                                      <span>
                                        {new Date(
                                          data.registerDate
                                        ).toLocaleString()}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="profile-about-me ">
                                  <div className="border-bottom-1 pb-4">
                                    <p>
                                      A wonderful serenity has taken possession
                                      of my entire soul, like these sweet
                                      mornings of spring which I enjoy with my
                                      whole heart. I am alone, and feel the
                                      charm of existence was created for the
                                      bliss of souls like mine.I am so happy, my
                                      dear friend, so absorbed in the exquisite
                                      sense of mere tranquil existence, that I
                                      neglect my talents.
                                    </p>
                                    <p>
                                      A collection of textile samples lay spread
                                      out on the table - Samsa was a travelling
                                      salesman - and above it there hung a
                                      picture that he had recently cut out of an
                                      illustrated magazine and housed in a nice,
                                      gilded frame.
                                    </p>
                                  </div>
                                </div>
                                <div className="profile-skills pt-2 border-bottom-1 pb-2">
                                  <h4 className="text-primary mb-4">Skills</h4>
                                  <Link
                                    to={"#"}
                                    className="btn btn-outline-dark btn-rounded px-4 me-2 mb-2">
                                    Admin
                                  </Link>
                                  <Link
                                    to={"#"}
                                    className="btn btn-outline-dark btn-rounded px-4 me-2 mb-2">
                                    Dashboard
                                  </Link>
                                  <Link
                                    to={"#"}
                                    className="btn btn-outline-dark btn-rounded px-4 me-2 mb-2">
                                    Photoshop
                                  </Link>
                                  <Link
                                    to={"#"}
                                    className="btn btn-outline-dark btn-rounded px-4 me-2 mb-2">
                                    Bootstrap
                                  </Link>
                                  <Link
                                    to={"#"}
                                    className="btn btn-outline-dark btn-rounded px-4 me-2 mb-2">
                                    Responsive
                                  </Link>
                                  <Link
                                    to={"#"}
                                    className="btn btn-outline-dark btn-rounded px-4 me-2 mb-2">
                                    Crypto
                                  </Link>
                                </div> */}
                                <div className="profile-lang pt-5 border-bottom-1 pb-5">
                                  <h4 className="text-primary mb-4">
                                    Language
                                  </h4>
                                  <span className="badge badge-primary light">
                                    {data.language}
                                  </span>{" "}
                                  {/* <span className="badge badge-primary light">
                                    French
                                  </span>{" "}
                                  <span className="badge badge-primary light">
                                    Bangla
                                  </span> */}
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const ProfileAdmin = () => {
  return (
    <>
      <PageTitle
        activeMenu="Staff Profile"
        motherMenu={"Staff"}
      />
      <AdminProfile />
    </>
  );
};

export { AdminProfile };
export default ProfileAdmin;
