import React, { useState } from "react";
import { Formik } from "formik";
import PageTitle from "../../layouts/PageTitle";
import * as Yup from "yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { EditAdmin } from "../../../API/Admins/EditAdmin";
import { Loader } from "rsuite";
import Swal from "sweetalert2";
import { queryClient } from "../../../App";
import { useNavigate, useParams } from "react-router-dom";
import { IMAGES } from "../../constant/theme";
import { Row } from "react-bootstrap";
import { GetSingleAdmin } from "../../../API/Admins/GetSingleAdmin";

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+$/;
const loginSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "Your username must consist of at least 3 characters ")
    .max(50, "Your username must consist of at least 3 characters ")
    .required("Please enter a username"),
  lastName: Yup.string()
    .min(3, "Your username must consist of at least 3 characters ")
    .max(50, "Your username must consist of at least 3 characters ")
    .required("Please enter a username"),
  password: Yup.string()
    .min(5, "Your password must be at least 5 characters long")
    .max(50, "Your password must be at least 5 characters long")
    .matches(
      passwordRegex,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
  email: Yup.string().email("Invalid email").required("Please provide a email"),
  phone: Yup.string()
    .required("Please provide a phone number")
    .test(
      "isValidPhoneNumber",
      "Phone number must be at least 11 digits and can't include letters",
      (value) => {
        const numbersOnly = value.replace(/\D/g, "");
        return numbersOnly.length >= 11;
      }
    ),
  // image: Yup.string().required("Please provide a image"),
});

const EditAdminPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { id } = useParams();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["SingleAdmin", id],
    queryFn: () => GetSingleAdmin(id),
  });

  const { mutate } = useMutation({
    mutationFn: (data) => EditAdmin(data, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["SingleAdmin"] });
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Admin Updated Successfully",
      });
      navigate("/all-admins");
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    },
  });
  const navigate = useNavigate();

  if (isError) return <p>Error</p>;

  if (isLoading)
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            class="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">Loading...</span>
        </div>
      </Row>
    );

  const initialValues = {
    firstName: data.firstName || "",
    lastName: data.lastName || "",
    email: data.email || "",
    phone: data.phone || "",
  };
  return (
    <>
      <PageTitle
        activeMenu={"Edit Admin"}
        motherMenu={"Admins"}
        userId={id}
        goBack
      />
      <div className="row">
        <div className="col-xl-12 col-xxl-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Admin Info</h5>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={loginSchema}
              // validateOnBlur={true}
              // validateOnChange={true}
              onSubmit={(values, actions) => {
                const changedValues = Object.keys(values).reduce((acc, key) => {
                  if (values[key] !== initialValues[key]) {
                    acc[key] = values[key]; // Include only changed values
                  }
                  return acc;
                }, {});
                actions.setSubmitting(true);
                mutate(changedValues, {
                  onSuccess: () => {
                    actions.setSubmitting(false);
                  },
                  onError: () => {
                    actions.setSubmitting(false);
                  },
                });
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="p-5">
                        <div className="author-profile">
                          <div className="author-media">
                            <img
                              src={"urlLing/" + data?.image}
                              alt="Profile Img"
                              onError={(e) => {
                                e.target.src = IMAGES.Avatpng1;
                              }}
                            />
                            <div
                              className="upload-link"
                              data-toggle="tooltip"
                              data-placement="right"
                              data-original-title="update">
                              <input
                                id="image"
                                name="image"
                                type="file"
                                className="update-flie"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <i className="fa fa-camera"></i>
                            </div>
                          </div>
                          {errors.image && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.image}
                            </div>
                          )}
                          <div className="author-info">
                            <h6 className="title">
                              {data?.firstName + " " + data?.lastName}
                            </h6>
                            <span>#{id}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="Enter_First_Name">
                            First Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="Enter_First_Name"
                            placeholder="Enter First Name"
                            type="text"
                            name="firstName"
                            className={`form-control ${
                              touched.firstName
                                ? errors.firstName
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.firstName && errors.firstName && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.firstName}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="Enter_Last_Name">
                            Last Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="Enter_Last_Name"
                            placeholder="Enter Last Name"
                            type="text"
                            name="lastName"
                            className={`form-control ${
                              touched.lastName
                                ? errors.lastName
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.lastName && errors.lastName && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.lastName}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="Email">
                            Email
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="Email"
                            placeholder="Email"
                            type="email"
                            name="email"
                            className={`form-control ${
                              touched.email
                                ? errors.email
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.email && errors.email && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.email}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div
                          className={`form-group mb-3 ${
                            values.password
                              ? errors.password
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}>
                          <label
                            className="form-label"
                            htmlFor="Password">
                            Password
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              id="Password"
                              placeholder="Password"
                              type={`${showPassword ? "text" : "password"}`}
                              maxLength="10"
                              name="password"
                              className={`form-control ${
                                touched.password
                                  ? errors.password
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div
                              className="input-group-text show-validate"
                              onClick={() => setShowPassword(!showPassword)}>
                              {showPassword === false ? (
                                <i className="fa fa-eye-slash" />
                              ) : (
                                <i className="fa fa-eye" />
                              )}
                            </div>
                            {touched.password && errors.password && (
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp">
                                {errors.password}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="Phone_Number">
                            Phone Number
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="Phone_Number"
                            placeholder="Phone Number"
                            type="text"
                            name="phone"
                            className={`form-control ${
                              touched.phone
                                ? errors.phone
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.phone && errors.phone && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.phone}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <button
                          id={`edit-admin-${id}`}
                          type="submit"
                          className="btn btn-primary me-1"
                          disabled={isSubmitting}>
                          {isSubmitting ? <Loader /> : "Update"}
                        </button>
                        <button
                          type="button"
                          onClick={() => navigate(-1)}
                          className="btn btn-danger light">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAdminPage;
