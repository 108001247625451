import React from "react";
import PageTitle from "../../layouts/PageTitle";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { GetSingleAdmin } from "../../../API/Admins/GetSingleAdmin";
import { AdminProfile } from "./AdminProfile";
import { Row } from "react-bootstrap";

const AboutAdmin = () => {
  const { id } = useParams();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["SingleAdmin"],
    queryFn: () => GetSingleAdmin(id),
  });

  if (isError)
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <span className="text-primary">Something went wrong...</span>
        </div>
      </Row>
    );

  if (isLoading)
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            class="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">Loading...</span>
        </div>
      </Row>
    );
  return (
    <>
      <PageTitle
        activeMenu={"About Admin"}
        motherMenu={"Admins"}
        userId={id}
        goBack={true}
      />

      {data && !isLoading && <AdminProfile data={data} />}
    </>
  );
};

export default AboutAdmin;
