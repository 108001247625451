import React, { useState, useEffect } from "react";
import { Dropdown, Row, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import PageTitle from "../../layouts/PageTitle";
import { DatePicker } from "rsuite";
import { useMutation, useQuery } from "@tanstack/react-query";
import { IMAGES } from "../../constant/theme";
import { queryClient } from "../../../App";
import Swal from "sweetalert2";
import { DeleteCommunity } from "../../../API/Communities/DeleteCommunity";
import { GetAllCommunities } from "../../../API/Communities/GetAllCommunities";
import { IMG_URL } from "../../layouts/nav/Header";
const AllCommunities = () => {
  const [feeData, setFeeDate] = useState();
  const [search, setSearch] = useState("");
  const [date, setDate] = useState("");
  const [open, setOpen] = useState(true);

  const {
    data: AllCommunities,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["AllCommunities"],
    queryFn: () => GetAllCommunities(),
  });

  useEffect(() => {
    setFeeDate(AllCommunities);
  }, [AllCommunities]);
  const { mutate } = useMutation({
    mutationFn: (id) => DeleteCommunity(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["AllCommunities"] });
    },
    onError: () => {
      Swal.fire("Error!", "Something went wrong.", "error");
    },
  });

  function deleteCommunity(id) {
    Swal.fire({
      title: "Are you sure to you want to delete this community ?",
      text: "You will not be able to recover this imaginary file !!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df0d0d",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        mutate(id);
        Swal.fire(
          "Deleted!",
          ` Community with id #${id} has been deleted successfully.`,
          "success"
        );
      }
    });
  }

  function DataSearch(e) {
    const updatesDate = AllCommunities.filter((item) => {
      let selectdata = `${item.title} ${item.id}`.toLowerCase();
      // item.name.toLowerCase() || item.id;
      return selectdata.includes(e.toLowerCase());
    });
    setFeeDate([...updatesDate] || AllCommunities);
  }
  const handleDateChange = (value) => {
    // Format the Date object to an ISO string and then slice to get "YYYY-MM-DD"
    const formattedDate = value
      ? new Date(value).toISOString().slice(0, 10)
      : "";
    setDate(formattedDate);
  };

  function resetFilters() {
    setFeeDate(AllCommunities);
    setDate("");
    setSearch("");
  }
  if (isError) {
    error.response.data.error === "In valid email or password" &&
      error.response.status === 403 &&
      Swal.fire({
        title: "Opps",
        text: "You must login again!",
        icon: "warning",
        confirmButtonColor: "#df0d0d",
        confirmButtonText: "Navigate to Login",
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("token");
          // navigate("/login");
          window.location.reload();
        }
      });
    return;
  }
  if (
    isError &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        {" "}
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          Something went wrong
        </div>
      </Row>
    );
  }
  if (isLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            class="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">Loading...</span>
        </div>
      </Row>
    );
  }
  return (
    <>
      <PageTitle
        activeMenu={"All Communities"}
        motherMenu={"Communities"}
      />

      <div className="filter cm-content-box box-primary">
        <div
          className="content-title"
          onClick={() => setOpen(!open)}>
          <div className="cpa">
            <i className="fas fa-filter me-2"></i>Filter
          </div>
          <div className="tools">
            <Link
              to={"#"}
              className={`SlideToolHeader ${open ? "collapse" : "expand"}`}>
              <i className="fas fa-angle-up"></i>
            </Link>
          </div>
        </div>

        <Collapse in={open}>
          <div className="cm-content-body form excerpt">
            <div className="card-body">
              <div className="row filter-row">
                <div className="col-xl-3 col-sm-6">
                  <label className="form-label">Search</label>
                  <input
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    type="text"
                    className="form-control mb-xl-0 mb-3"
                    id="exampleFormControlInput1"
                    placeholder="Search by Name or ID"
                    onKeyDown={(e) =>
                      e.key === "Enter" && DataSearch(search || date)
                    }
                  />
                </div>
                <div className="col-xl-3 col-sm-6">
                  <label className="form-label">Register Date</label>
                  <DatePicker
                    placeholder="Register Date"
                    className="picker-suit"
                    onChange={handleDateChange}
                    value={date ? new Date(date) : null}
                    format="yyyy-MM-dd"
                  />
                </div>
                <div className="col-xl-3 col-sm-6 align-self-end">
                  <button
                    onClick={() => DataSearch(search || date)}
                    className="btn btn-primary rounded-sm me-2"
                    title="Click here to Search"
                    type="button">
                    <i className="fa fa-search me-1" />
                    Filter
                  </button>
                  <button
                    onClick={() => resetFilters()}
                    className="btn btn-danger rounded-sm light"
                    title="Click here to remove filter"
                    type="button">
                    Remove Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
      {feeData && (
        <Row key={"Grid"}>
          <Tab.Container defaultActiveKey={"Grid"}>
            <div className="col-lg-12">
              <Tab.Content className="row tab-content">
                <Tab.Pane
                  eventKey="Grid"
                  className="col-lg-12">
                  <div className="page-titles">
                    <div className="d-flex gap-3 align-items-center justify-content-between">
                      <h4 className="card-title">All Communities List </h4>
                      <Link
                        to={"add-community"}
                        className="btn btn-primary">
                        + Add New Community
                      </Link>
                    </div>
                  </div>
                  <div className="row">
                    {feeData && feeData.length === 0 && (
                      <div className="col-lg-12">
                        <div
                          className="alert alert-danger"
                          role="alert">
                          No data found
                        </div>
                      </div>
                    )}
                    {feeData.map((item, ind) => (
                      <div
                        className="col-lg-3 col-md-6 col-sm-6 col-12"
                        key={ind}>
                        <div className="card card-profile">
                          <div className="card-header justify-content-end pb-0 border-0">
                            <Dropdown>
                              <Dropdown.Toggle
                                as="button"
                                className="btn btn-link i-false"
                                type="button">
                                <span className="dropdown-dots fs--1"></span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                align="end"
                                className="dropdown-menu dropdown-menu-right border py-0">
                                <div className="py-2">
                                  <Link
                                    to={"edit-community/" + item.id}
                                    className="dropdown-item">
                                    Edit
                                  </Link>
                                  <Link
                                    onClick={() => deleteCommunity(item.id)}
                                    className="dropdown-item text-danger">
                                    Delete
                                  </Link>
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <div className="card-body pt-2">
                            <div className="text-center">
                              <div className="profile-photo">
                                <img
                                  src={
                                    IMG_URL +
                                    "LMST/ADMIN/IMAGES/COMMUNITY/" +
                                    item.logo
                                  }
                                  width="100"
                                  className="img-fluid rounded-circle min-h-100px min-w-100px"
                                  alt="Community Img"
                                  onError={(e) => {
                                    e.target.src = IMAGES.Avatpng1;
                                  }}
                                />
                              </div>
                              <h3 className="mt-4 mb-1 text-primary">
                                {item.title}
                              </h3>
                              <p className="text-muted">#{item.id}</p>
                              <ul className="list-group mb-3 list-group-flush">
                                <li className="list-group-item px-0 d-flex justify-content-between align-items-center">
                                  <span className="mb-0">
                                    <img
                                      src={item.managerImage}
                                      alt="Manager_Image"
                                      className="rounded-circle me-2"
                                      width="35"
                                      onError={(e) => {
                                        e.target.src = IMAGES.Avatpng1;
                                      }}
                                    />
                                    Teacher :
                                  </span>
                                  <strong>{item.managerName}</strong>
                                </li>
                                <li className="list-group-item px-0 d-flex justify-content-between align-items-center">
                                  <span className="mb-0">
                                    Max Allowed Storage :
                                  </span>
                                  <strong>{item.maxStorage}</strong>
                                </li>
                                <li className="list-group-item px-0 d-flex justify-content-between align-items-center">
                                  <span className="mb-0">Used Storage :</span>
                                  <strong>{item.usedStorage}</strong>
                                </li>
                                <li className="list-group-item px-0 d-flex justify-content-between align-items-center">
                                  <span className="mb-0">
                                    Max Allowed Students :
                                  </span>
                                  <strong>{item.maxStudentNo}</strong>
                                </li>
                                <li className="list-group-item px-0 d-flex justify-content-between align-items-center">
                                  <span className="mb-0">
                                    Students Number :
                                  </span>
                                  <strong>{item.studentNo}</strong>
                                </li>
                                <li className="list-group-item px-0 d-flex justify-content-between align-items-center">
                                  <span className="mb-0">Register Date :</span>
                                  <strong>
                                    {new Date(
                                      item.registerDate
                                    ).toLocaleString()}
                                  </strong>
                                </li>
                                <li className="list-group-item px-0 d-flex justify-content-between align-items-center">
                                  <span className="mb-0">Status :</span>
                                  <strong>
                                    {item.active ? "Active" : "Inactive"}
                                  </strong>
                                </li>
                                <li className="list-group-item px-0 d-flex justify-content-between align-items-center">
                                  <span className="mb-0">Package :</span>
                                  <strong>
                                    {item.maxStudentNo <= 250 && "Silver"}
                                    {item.maxStudentNo > 250 &&
                                      item.maxStudentNo <= 550 &&
                                      "Gold"}
                                    {item.maxStudentNo > 550 && "VIP"}
                                  </strong>
                                </li>
                              </ul>
                              <Link
                                to={"about-community/" + item.id}
                                id={`open-community-${item.id}`}
                                className="btn btn-primary mt-3 px-4 w-100">
                                Open Community
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
          <p>
            {"Showing " +
              (feeData.length > 0 ? feeData.length : 0) +
              " of " +
              AllCommunities.length}
          </p>
        </Row>
      )}
    </>
  );
};

export default AllCommunities;
