import { Upload } from "@aws-sdk/lib-storage";
import s3Client from "./aws";
export async function UploadToS3(imageName, imageFile, path) {
  try {
    const parallelUploads3 = new Upload({
      client: s3Client,
      params: {
        Bucket: process.env.REACT_APP_S3_BUCKET,
        Key: path + imageName,
        Body: imageFile,
      },
      leavePartsOnError: false, // Optional: Force cleanup of parts if the upload fails
    });
    // parallelUploads3.on("httpUploadProgress", (progress) => {
    //   console.log("Upload progress:", progress);
    // });
    // /LMST/ADMIN/IMAGES/PROFILE
    await parallelUploads3.done();

    return `${process.env.REACT_APP_S3_ENDPOINT}/${
      process.env.REACT_APP_S3_BUCKET
    }/${path + imageName}`;

    // The file is successfully uploaded
  } catch (err) {
    console.log("Error", err);
    // Handle any error occurred during upload
  }
}
