import axiosInstance from "../../services/AxiosInstance";

export async function AddNewAdmin(data) {
  return axiosInstance
    .post("admin-api/admins", data)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching admins:", error);
      throw error; // Rethrow to be caught by React Query error handling
    });
}
