import axiosInstance from "../../services/AxiosInstance";

export async function DeleteAdmin(id) {
  return axiosInstance
    .delete(`admin-api/admins/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching admins:", error);
      throw error; // Rethrow to be caught by React Query error handling
    });
}
