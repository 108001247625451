import { Tab, Nav } from "react-bootstrap";

import PageTitle from "../../layouts/PageTitle";
import { IMAGES } from "../../constant/theme";
import { Link } from "react-router-dom";
import { SuspednTeacher } from "../../../API/Teachers/SuspednTeacher";
import { queryClient } from "../../../App";
import Swal from "sweetalert2";
import { useMutation } from "@tanstack/react-query";

function TeacherProfile({ data, id }) {
  const { mutate: SuspendTeacher } = useMutation({
    mutationFn: () => SuspednTeacher(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["SingleTeacher"] });
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Admin Added Successfully",
      });
    },
    onError: () => {

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    },
  });
  return (
    <>
      <div className="row">
        <div className=" col-lg-12">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="profile-tab">
                    <div className="custom-tab-1">
                      <Tab.Container defaultActiveKey={"Personal"}>
                        <Nav
                          as="ul"
                          className="nav nav-tabs">
                          <Nav.Item as="li">
                            <Nav.Link eventKey="Personal">Personal</Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="Courses">Courses</Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="Feedback">Feedback</Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="Exams">Exams</Nav.Link>
                          </Nav.Item>

                          <div className="d-flex gap-3 align-items-center  ms-auto">
                            <Link
                              onClick={() => SuspendTeacher(data.id)}
                              className="btn sharp btn-danger px-3 ">
                              {data.active ? "Suspend" : "Activate"}
                            </Link>
                          </div>
                        </Nav>

                        <Tab.Content className="tab-content">
                          <Tab.Pane eventKey={"Personal"}>
                            <div className="d-flex justify-content-between flex-md-row flex-column gap-xsm-1 gap-md-4 gap-3">
                              <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12 pt-4 ">
                                <div className="">
                                  <div className="card overflow-hidden">
                                    <div
                                      className="text-center p-3 overlay-box"
                                      // style={{
                                      //   backgroundImage: `url(${IMAGES.BigImg1})`,
                                      // }}
                                    >
                                      <div className="profile-photo">
                                        <img
                                          src={"url/" + data.image}
                                          width="100"
                                          className="img-fluid rounded-circle"
                                          alt=""
                                          onError={(e) => {
                                            e.target.src = IMAGES.Avatpng1;
                                          }}
                                        />
                                      </div>
                                      <p className="text-white mb-0">
                                        {data.firstName + " " + data.lastName}
                                      </p>
                                    </div>
                                    <ul className="list-group list-group-flush bg-light">
                                      <li className="list-group-item d-flex justify-content-between border-dark  py-3 ">
                                        <span className="mb-1">
                                          {data.firstName + " " + data.lastName}
                                        </span>
                                      </li>
                                      <li className="list-group-item d-flex justify-content-between border-dark  py-3">
                                        <span className="mb-1">#{data.id}</span>
                                      </li>
                                      <li className="list-group-item d-flex justify-content-between border-dark  py-3">
                                        <span className="mb-1">
                                          {data.email}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-9 col-lg-7 col-md-7 col-sm-12 mt-4 bg-light rounded p-4">
                                <div className="profile-personal-info">
                                  <h3 className="text-dark  mb-4 border-bottom pb-2 border-dark">
                                    About {data.firstName + " " + data.lastName}
                                  </h3>

                                  <div className="profile-about-me text-muted pb-4">
                                    <div className="border-bottom-1 pb-4">
                                      <p>{data.overview}</p>
                                    </div>
                                  </div>

                                  <div className="row mb-4">
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                      <h5 className="f-w-500">
                                        First Name
                                        <span className="pull-right">:</span>
                                      </h5>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                                      <span>{data.firstName}</span>
                                    </div>
                                  </div>
                                  <div className="row mb-4">
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                      <h5 className="f-w-500">
                                        Last Name
                                        <span className="pull-right">:</span>
                                      </h5>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                                      <span>{data.lastName}</span>
                                    </div>
                                  </div>
                                  <div className="row mb-4">
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                      <h5 className="f-w-500">
                                        Email
                                        <span className="pull-right">:</span>
                                      </h5>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                                      <span>{data.email}</span>
                                    </div>
                                  </div>
                                  <div className="row mb-4">
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                      <h5 className="f-w-500">
                                        Phone Number
                                        <span className="pull-right">:</span>
                                      </h5>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                                      <span>+{data.phone}</span>
                                    </div>
                                  </div>
                                  <div className="row mb-4">
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                      <h5 className="f-w-500">
                                        Country
                                        <span className="pull-right">:</span>
                                      </h5>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                                      <span>{data.country}</span>
                                    </div>
                                  </div>
                                  <div className="row mb-4">
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                      <h5 className="f-w-500">
                                        Address
                                        <span className="pull-right">:</span>
                                      </h5>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                                      <span>
                                        {data.address}, {data.city}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="row mb-4">
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                      <h5 className="f-w-500">
                                        Status
                                        <span className="pull-right">:</span>
                                      </h5>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                                      <span>
                                        {data.active ? "Active" : "Not Active"}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="row mb-4">
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                      <h5 className="f-w-500">
                                        Verified
                                        <span className="pull-right">:</span>
                                      </h5>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                                      <span>
                                        {data.verified
                                          ? "Verified"
                                          : "Not Verified"}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="row mb-4">
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                      <h5 className="f-w-500">
                                        Register Date
                                        <span className="pull-right">:</span>
                                      </h5>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                                      <span>
                                        {new Date(
                                          data.registerDate
                                        ).toLocaleString()}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="profile-skills pt-2 border-bottom-1 pb-2">
                                  <h4 className="text-primary mb-4">
                                    Community
                                  </h4>
                                  <Link
                                    to={"#"}
                                    className="btn btn-outline-dark btn-rounded px-4 me-2 mb-2">
                                    {data.communityName}
                                  </Link>
                                  {/* <Link
                                    to={"#"}
                                    className="btn btn-outline-dark btn-rounded px-4 me-2 mb-2">
                                    Dashboard
                                  </Link>
                                  <Link
                                    to={"#"}
                                    className="btn btn-outline-dark btn-rounded px-4 me-2 mb-2">
                                    Photoshop
                                  </Link>
                                  <Link
                                    to={"#"}
                                    className="btn btn-outline-dark btn-rounded px-4 me-2 mb-2">
                                    Bootstrap
                                  </Link>
                                  <Link
                                    to={"#"}
                                    className="btn btn-outline-dark btn-rounded px-4 me-2 mb-2">
                                    Responsive
                                  </Link>
                                  <Link
                                    to={"#"}
                                    className="btn btn-outline-dark btn-rounded px-4 me-2 mb-2">
                                    Crypto
                                  </Link> */}
                                </div>
                                <div className="profile-lang pt-5 border-bottom-1 pb-5">
                                  <h4 className="text-primary mb-4">
                                    Language
                                  </h4>
                                  <span className="badge badge-primary light">
                                    {data.language}
                                  </span>
                                </div>
                                <div className="profile-lang pt-5 border-bottom-1 pb-5">
                                  <h4 className="text-primary mb-4">
                                    Experience
                                  </h4>
                                  <div className="row mb-4">
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                      <h5 className="f-w-500">
                                        Jop Title
                                        <span className="pull-right">:</span>
                                      </h5>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                                      <span>{data.jobTitle}</span>
                                    </div>
                                  </div>
                                  <div className="row mb-4">
                                    <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                      <h5 className="f-w-500">
                                        Experience Year
                                        <span className="pull-right">:</span>
                                      </h5>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                                      <span>{data.experience} Years</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                        <Tab.Content className="tab-content">
                          <Tab.Pane eventKey={"Courses"}>
                            <div className="d-flex justify-content-between flex-md-row flex-column gap-xsm-1 gap-md-4 gap-3">
                              <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12 pt-4 ">
                                Soon...
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey={"Feedback"}>
                            <div className="d-flex justify-content-between flex-md-row flex-column gap-xsm-1 gap-md-4 gap-3">
                              <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12 pt-4 ">
                                Soon...
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey={"Exams"}>
                            <div className="d-flex justify-content-between flex-md-row flex-column gap-xsm-1 gap-md-4 gap-3">
                              <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12 pt-4 ">
                                Soon...
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const ProfileAdmin = () => {
  return (
    <>
      <PageTitle
        activeMenu="Staff Profile"
        motherMenu={"Staff"}
      />
      <TeacherProfile />
    </>
  );
};

export { TeacherProfile };
export default ProfileAdmin;
