import axiosInstance from "../../services/AxiosInstance";

export async function GetAllCommunities() {
  return axiosInstance
    .get("admin-api/communities")
    .then((response) => response.data) // Return directly the data part of the response
    .catch((error) => {
      console.error("Error fetching communities:", error);
      throw error; // Rethrow to be caught by React Query error handling
    });
}
