import axios from "axios";
// import swal from "sweetalert";
import Swal from "sweetalert2";
import { loginConfirmedAction, Logout } from "../store/actions/AuthActions";
import { extractExpDate } from "../util/extractExpDate";

// export function signUp(email, password) {
//   //axios call
//   const postData = {
//     email,
//     password,
//     returnSecureToken: true,
//   };
//   return axios.post(
//     `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
//     postData
//   );
// }

export function login(email, password) {
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  return axios.post(
    `https://api-host.goteacher.net/auth/admin/login`,
    postData
  );
}

export function formatError(errorResponse) {
  switch (errorResponse.data.error) {
    case "Bad credentials":
      //return 'Email already exists';
      // swal("Oops", "Email already exists", "error");
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Invalid Email or Password",
      });
      break;
    case "User with specified Role is NOT Found":
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Please Enter a valid Teacher Email",
      });
      break;
    case errorResponse.data.error && errorResponse.status === 500:
      //return 'Invalid Password';
      // swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Something went wrong. Please try again or contact support",
      });
      break;
    case errorResponse.data.error && errorResponse.status !== 500:
      //return 'Invalid Password';
      // swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: errorResponse.data.error,
      });
      break;
    case errorResponse.data.error === "In valid email or password" &&
      errorResponse.status === 403:
      Swal.fire({
        title: "Opps",
        text: "You must login again!",
        icon: "warning",
        confirmButtonColor: "#df0d0d",
        confirmButtonText: "Navigate to Login",
      }).then((result) => {
        localStorage.removeItem("userDetails");
        localStorage.removeItem("token");
        // if (result.isConfirmed) {
        //   window.location.href = "/login";
        // }
      });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  const tokenData = extractExpDate(tokenDetails);
  //   const expireDate = new Date(new Date().getTime() + tokenExpireDate);
  const userData = {
    email: tokenData.email,
    displayName: tokenData.name,
    idToken: tokenDetails,
    registered: true,
    expiresIn: tokenData.exp,
    expireDate: new Date(tokenData.exp * 1000).toISOString(),
  };
  localStorage.setItem("userDetails", JSON.stringify(userData));
  localStorage.setItem("token", userData.idToken);
}

export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    //dispatch(Logout(history));
    dispatch(Logout(navigate));
  }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
  const tokenDetailsString = localStorage.getItem("userDetails");
  let tokenDetails = "";
  if (!tokenDetailsString) {
    dispatch(Logout(navigate));
    return;
  }

  tokenDetails = JSON.parse(tokenDetailsString);
  let expireDate = new Date(tokenDetails.expireDate);
  let todaysDate = new Date();

  if (todaysDate > expireDate) {
    dispatch(Logout(navigate));
    return;
  }

  dispatch(loginConfirmedAction(tokenDetails));

  const timer = expireDate.getTime() - todaysDate.getTime();
  runLogoutTimer(dispatch, timer, navigate);
}
