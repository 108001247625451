import React, { useState, useRef, useEffect } from "react";
import { Badge, Dropdown, Row, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import PageTitle from "../../layouts/PageTitle";
import { DatePicker } from "rsuite";
import { useMutation, useQuery } from "@tanstack/react-query";
import { IMAGES } from "../../constant/theme";
import { queryClient } from "../../../App";
import Swal from "sweetalert2";
import { GetAllTeachers } from "../../../API/Teachers/GetAllTeachers";
import { DeleteTeacher } from "../../../API/Teachers/DeleteTeacher";

const theadData = [
  { heading: "ID.", sortingVale: "rollno" },
  { heading: "Name", sortingVale: "name" },
  { heading: "Status", sortingVale: "status" },
  { heading: "Email", sortingVale: "email" },
  { heading: "Mobile", sortingVale: "mobile" },
  { heading: "Communities", sortingVale: "communities" },
  { heading: "Verified", sortingVale: "verified" },
  { heading: "Registration Date", sortingVale: "join" },
  { heading: "Action", sortingVale: "action" },
];

const AllTeachers = () => {
  const [sort, setSortata] = useState(10);
  const [feeData, setFeeDate] = useState();
  const [search, setSearch] = useState("");
  const [date, setDate] = useState("");
  const [iconData, setIconDate] = useState({ complete: false, ind: Number });

  const {
    data: allTeachers,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["AllTeachers"],
    queryFn: () => GetAllTeachers(),
  });

  const { mutate } = useMutation({
    mutationFn: (id) => DeleteTeacher(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["AllTeachers"] });
    },
    onError: () => {
      Swal.fire("Error!", "Something went wrong.", "error");
    },
  });

  const [data, setData] = useState(
    document.querySelectorAll("#holidayList tbody tr")
  );
  const finalData = feeData || allTeachers;
  const activePag = useRef(0);
  const [test, settest] = useState(0);
  const [open, setOpen] = useState(true);

  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  function deleteTeacher(id) {
    Swal.fire({
      title: "Are you sure to you want to delete this admin ?",
      text: "You will not be able to recover this imaginary file !!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df0d0d",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        mutate(id);
        Swal.fire(
          "Deleted!",
          ` Admin with id #${id} has been deleted successfully.`,
          "success"
        );
      }
    });
  }

  useEffect(() => {
    setData(document.querySelectorAll("#holidayList tbody tr"));
  }, [test, finalData]);

  activePag.current === 0 && chageData(0, sort);

  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };
  function SotingData(name) {
    const sortedPeople = [...allTeachers];
    switch (name) {
      case "rollno":
        sortedPeople.sort((a, b) => {
          return iconData.complete ? a.id < b.id : b.id < a.id;
        });
        break;
      case "name":
        sortedPeople.sort((a, b) => {
          return iconData.complete
            ? a.firstName.localeCompare(b.firstName)
            : b.firstName.localeCompare(a.firstName);
        });
        break;
      case "communities":
        sortedPeople.sort((a, b) => {
          return iconData.complete
            ? a.firstName.localeCompare(b.firstName)
            : b.firstName.localeCompare(a.firstName);
        });
        break;
      case "verified":
        sortedPeople.sort((a, b) => {
          return iconData.complete ? a.verified : b.verified;
          // ? a.verified.localeCompare(b.verified)
          // : b.verified.localeCompare(a.verified);
        });
        break;
      case "status":
        sortedPeople.sort((a, b) => {
          return iconData.complete ? a.active : b.active;
          // ? a.verified.localeCompare(b.verified)
          // : b.verified.localeCompare(a.verified);
        });
        break;
      case "mobile":
        sortedPeople.sort((a, b) => {
          return iconData.complete
            ? a.phone.localeCompare(b.phone)
            : b.phone.localeCompare(a.phone);
        });
        break;
      case "join":
        sortedPeople.sort((a, b) => {
          return iconData.complete
            ? a.registerDate.localeCompare(b.registerDate)
            : b.registerDate.localeCompare(a.registerDate);
        });
        break;
      default:
        break;
    }
    setFeeDate(sortedPeople || allTeachers);
  }
  function DataSearch(e) {
    const updatesDate = allTeachers.filter((item) => {
      let selectdata = `${item.firstName}  ${item.id}`.toLowerCase();

      // item.name.toLowerCase() || item.id;
      return selectdata.includes(e.toLowerCase());
    });
    setFeeDate([...updatesDate] || allTeachers);
  }
  const handleDateChange = (value) => {
    // Format the Date object to an ISO string and then slice to get "YYYY-MM-DD"
    const formattedDate = value
      ? new Date(value).toISOString().slice(0, 10)
      : "";
    setDate(formattedDate);
  };

  function resetFilters() {
    setFeeDate(allTeachers);
    setDate("");
    setSearch("");
  }

  if (isError) {
    error.response.data.error === "In valid email or password" &&
      error.response.status === 403 &&
      Swal.fire({
        title: "Opps",
        text: "You must login again!",
        icon: "warning",
        confirmButtonColor: "#df0d0d",
        confirmButtonText: "Navigate to Login",
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("token");
          // navigate("/login");
          window.location.reload();
        }
      });
    return;
  }
  if (
    isError &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        {" "}
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          Something went wrong
        </div>
      </Row>
    );
  }
  if (isLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            class="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">Loading...</span>
        </div>
      </Row>
    );
  }

  return (
    <>
      <PageTitle
        activeMenu={"All Teachers"}
        motherMenu={"Teachers"}
      />

      <div className="filter cm-content-box box-primary">
        <div
          className="content-title"
          onClick={() => setOpen(!open)}>
          <div className="cpa">
            <i className="fas fa-filter me-2"></i>Filter
          </div>
          <div className="tools">
            <Link
              to={"#"}
              className={`SlideToolHeader ${open ? "collapse" : "expand"}`}>
              <i className="fas fa-angle-up"></i>
            </Link>
          </div>
        </div>

        <Collapse in={open}>
          <div className="cm-content-body form excerpt">
            <div className="card-body">
              <div className="row filter-row">
                <div className="col-xl-3 col-sm-6">
                  <label className="form-label">Search</label>
                  <input
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    type="text"
                    className="form-control mb-xl-0 mb-3"
                    id="exampleFormControlInput1"
                    placeholder="Search by Name or ID"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        DataSearch(e.target.value);
                      }
                    }}
                  />
                </div>
                <div className="col-xl-3 col-sm-6">
                  <label className="form-label">Register Date</label>
                  <DatePicker
                    placeholder="Register Date"
                    className="picker-suit"
                    onChange={handleDateChange}
                    value={date ? new Date(date) : null}
                    format="yyyy-MM-dd"
                  />
                </div>
                <div className="col-xl-3 col-sm-6 align-self-end">
                  <button
                    onClick={() => DataSearch(search || date)}
                    className="btn btn-primary rounded-sm me-2"
                    title="Click here to Search"
                    type="button">
                    <i className="fa fa-search me-1" />
                    Filter
                  </button>
                  <button
                    onClick={() => resetFilters()}
                    className="btn btn-danger rounded-sm light"
                    title="Click here to remove filter"
                    type="button">
                    Remove Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>

      <Row key={allTeachers._id}>
        <Tab.Container defaultActiveKey={"List"}>
          <div className="col-lg-12">
            <Tab.Content className="row tab-content">
              <Tab.Pane
                eventKey="List"
                className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">All Teachers List </h4>
                    <div className="d-flex gap-3 align-items-center">
                      <Link
                        to={"add-teacher"}
                        className="btn btn-primary">
                        + Add New Teacher
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div
                        id="holidayList"
                        className="dataTables_wrapper no-footer">
                        <div className="justify-content-between d-sm-flex">
                          <div className="dataTables_length">
                            <label className="d-flex align-items-center">
                              Show
                              <Dropdown className="search-drop">
                                <Dropdown.Toggle
                                  as="div"
                                  className="search-drop-btn">
                                  {sort}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => setSortata("10")}>
                                    10
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => setSortata("20")}>
                                    20
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => setSortata("30")}>
                                    30
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                              entries
                            </label>
                          </div>
                        </div>
                        <table
                          id="example4"
                          className="display dataTable no-footer w-100">
                          <thead>
                            <tr>
                              {theadData.map((item, ind) => (
                                <th
                                  key={ind}
                                  onClick={() => {
                                    SotingData(item.sortingVale);
                                    setIconDate((prevState) => ({
                                      complete: !prevState.complete,
                                      ind: ind,
                                    }));
                                  }}>
                                  {item.heading}
                                  <span>
                                    {ind !== iconData.ind && (
                                      <i
                                        className="fa fa-sort ms-2 fs-12"
                                        style={{ opacity: "0.3" }}
                                      />
                                    )}
                                    {ind === iconData.ind &&
                                      (iconData.complete ? (
                                        <i
                                          className="fa fa-arrow-down ms-2 fs-12"
                                          style={{ opacity: "0.7" }}
                                        />
                                      ) : (
                                        <i
                                          className="fa fa-arrow-up ms-2 fs-12"
                                          style={{ opacity: "0.7" }}
                                        />
                                      ))}
                                  </span>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {!finalData || finalData.length === 0 ? (
                              <p className="pt-5">No data found</p>
                            ) : (
                              finalData.map((data, ind) => (
                                <tr key={ind}>
                                  <td>
                                    <strong>{data.id}</strong>
                                  </td>
                                  <td>
                                    <img
                                      className="rounded-circle me-2"
                                      width="35"
                                      src={data.image}
                                      alt="Person"
                                      onError={(e) => {
                                        e.target.src = IMAGES.Avatpng1;
                                      }}
                                    />
                                    {data.firstName + " " + data.lastName}
                                  </td>
                                  <td>
                                    <Badge
                                      bg=""
                                      className={
                                        data.active
                                          ? `badge-success light`
                                          : `badge-danger light`
                                      }>
                                      {data.active ? "Active" : "Inactive"}
                                    </Badge>
                                  </td>
                                  <td>
                                    <Link to={"#"}>
                                      <strong>{data.email}</strong>
                                    </Link>
                                  </td>
                                  <td>
                                    <Link to={"#"}>
                                      <strong>+{data.phone}</strong>
                                    </Link>
                                  </td>
                                  <td>
                                    <img
                                      className="rounded-circle me-2"
                                      width="35"
                                      src={data.communityLogo}
                                      alt="Person"
                                      onError={(e) => {
                                        e.target.src = IMAGES.Avatpng1;
                                      }}
                                    />
                                    {data.communityName}
                                  </td>

                                  <td>
                                    {data.verified === true ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="#7ED321"
                                        class="bi bi-check-circle-fill"
                                        viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="#FF1616"
                                        class="bi bi-x-circle-fill"
                                        viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                                      </svg>
                                    )}
                                  </td>

                                  <td>
                                    {new Date(data.registerDate).toDateString()}
                                  </td>
                                  <td className="d-flex justify-content-between align-items-center ">
                                    <Link
                                      to={"/all-teachers/" + data.id}
                                      className="btn sharp btn-primary d-flex align-items-center gap-2">
                                      <span>Open</span>
                                      <i className="fa fa-arrow-right" />
                                    </Link>
                                    <Link
                                      to={"edit-teacher/" + data.id}
                                      id={`edit-` + data.id}
                                      className="btn sharp btn-primary ">
                                      <i className="fa fa-pencil" />
                                    </Link>
                                    <Link
                                      onClick={() => deleteTeacher(data.id)}
                                      id={`delete-` + data.id}
                                      className="btn sharp btn-danger ">
                                      <i className="fa fa-trash" />
                                    </Link>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                        <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                          <div className="dataTables_info">
                            Showing {activePag.current * sort + 1} to{" "}
                            {data.length > (activePag.current + 1) * sort
                              ? (activePag.current + 1) * sort
                              : data.length}{" "}
                            of {data.length} entries
                          </div>
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="example5_paginate">
                            <Link
                              className="paginate_button previous disabled"
                              to="#"
                              onClick={() =>
                                activePag.current > 0 &&
                                onClick(activePag.current - 1)
                              }>
                              Previous
                            </Link>
                            <span>
                              {paggination.map((number, i) => (
                                <Link
                                  key={i}
                                  to="#"
                                  className={`paginate_button  ${
                                    activePag.current === i ? "current" : ""
                                  } `}
                                  onClick={() => onClick(i)}>
                                  {number}
                                </Link>
                              ))}
                            </span>
                            <Link
                              className="paginate_button next"
                              to="#"
                              onClick={() =>
                                activePag.current + 1 < paggination.length &&
                                onClick(activePag.current + 1)
                              }>
                              Next
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              {/* <Tab.Pane
                eventKey="Grid"
                className="col-lg-12">
                <div className="row">
                  {gridDataBlog.map((item, ind) => (
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 col-12"
                      key={ind}>
                      <div className="card card-profile">
                        <div className="card-header justify-content-end pb-0 border-0">
                          <Dropdown>
                            <Dropdown.Toggle
                              as="button"
                              className="btn btn-link i-false"
                              type="button">
                              <span className="dropdown-dots fs--1"></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              align="end"
                              className="dropdown-menu dropdown-menu-right border py-0">
                              <div className="py-2">
                                <Link
                                  to={"#"}
                                  className="dropdown-item">
                                  Edit
                                </Link>
                                <Link
                                  to={"#"}
                                  className="dropdown-item text-danger">
                                  Delete
                                </Link>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="card-body pt-2">
                          <div className="text-center">
                            <div className="profile-photo">
                              <img
                                src={item.image}
                                width="100"
                                className="img-fluid rounded-circle"
                                alt=""
                              />
                            </div>
                            <h3 className="mt-4 mb-1">{item.name}</h3>
                            <p className="text-muted">{item.subject}</p>
                            <ul className="list-group mb-3 list-group-flush">
                              {item.content.map((data, ind) => (
                                <li
                                  className="list-group-item px-0 d-flex justify-content-between"
                                  key={ind}>
                                  <span className="mb-0">{data.title} :</span>
                                  <strong>{data.subtitle}</strong>
                                </li>
                              ))}
                            </ul>
                            <Link
                              to={"/about-student"}
                              className="btn btn-outline-primary btn-rounded mt-3 px-4">
                              Read More
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Tab.Pane> */}
            </Tab.Content>
          </div>
        </Tab.Container>
      </Row>
    </>
  );
};

export default AllTeachers;
