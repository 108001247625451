import axiosInstance from "../../services/AxiosInstance";

export async function GetSingleAdmin(id) {
  return axiosInstance
    .get("admin-api/admins/" + id)
    .then((response) => response.data) // Return directly the data part of the response
    .catch((error) => {
      console.error("Error fetching admins:", error);
      throw error; // Rethrow to be caught by React Query error handling
    });
}
