import React, { useState } from "react";
import { Formik } from "formik";
import PageTitle from "../../layouts/PageTitle";
import * as Yup from "yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Loader } from "rsuite";
import Swal from "sweetalert2";
import { queryClient } from "../../../App";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { COUNTRIES } from "../../../util/Countries";
import { GetSingleTeacher } from "../../../API/Teachers/GetSingleTeacher";
import { Row } from "react-bootstrap";
import { EditTeacher } from "../../../API/Teachers/EditTeacher";
import { IMAGES } from "../../constant/theme";
import { SuspednTeacher } from "../../../API/Teachers/SuspednTeacher";

const loginSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "Your username must consist of at least 3 characters ")
    .max(50, "Your username must consist of at least 3 characters ")
    .required("Please enter a username"),
  lastName: Yup.string()
    .min(3, "Your username must consist of at least 3 characters ")
    .max(50, "Your username must consist of at least 3 characters ")
    .required("Please enter a username"),
  // password: Yup.string()
  //   .min(5, "Your password must be at least 5 characters long")
  //   .max(50, "Your password must be at least 5 characters long")
  //   .required("Please provide a password")
  //   .matches(
  //     passwordRegex,
  //     "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
  //   ),
  // confirmPassword: Yup.string()
  //   .oneOf([Yup.ref("password"), null], "Passwords must match")
  //   .required("Please confirm your password"),
  email: Yup.string().email("Invalid email").required("Please provide a email"),
  phone: Yup.string()
    .required("Please provide a phone number")
    .test(
      "isValidPhoneNumber",
      "Phone number must be at least 11 digits and can't include letters",
      (value) => {
        const numbersOnly = value.replace(/\D/g, "");
        return numbersOnly.length >= 11;
      }
    ),
  communityId: Yup.string()
    .required("Please provide a CommunityId number")
    .test(
      "isValidcommunityId",
      "CommunityId number must be at least 1 digits and can't include letters",
      (value) => {
        const numbersOnly = value.replace(/\D/g, "");
        return numbersOnly.length >= 1;
      }
    ),
  jobTitle: Yup.string().required("Please provide a job title"),
  experience: Yup.string()
    .required("Please provide a experience years number")
    .test(
      "isValidexperience",
      "Experience number must be at least 1 digits and can't include letters",
      (value) => {
        const numbersOnly = value.replace(/\D/g, "");
        return numbersOnly.length >= 1;
      }
    ),
  language: Yup.string().required("Please select a language"),

  country: Yup.string()
    .required("Please select a country")
    .notOneOf([""], "Please select a language"),
  address: Yup.string().required("Please provide an address"),
  city: Yup.string().required("Please provide a city"),
  overview: Yup.string().required("Please provide an overview"),
});

const languages = [
  { value: "", label: "Select Language" },
  { value: "Arabic", label: "Arabic" },
  { value: "English", label: "English" },
];

const customStyles = (error, touched) => ({
  control: (provided) => ({
    ...provided,
    borderColor: touched && error ? "red" : provided.borderColor,
    "&:hover": {
      borderColor: touched && error ? "red" : provided.borderColor,
    },
    boxShadow: touched && error ? "0 0 0 1px red" : "none",
  }),
});

const AddTeacher = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    data: singleTeacher,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["SingleTeacher", id],
    queryFn: () => GetSingleTeacher(id),
  });
  const { mutate } = useMutation({
    mutationFn: (data) => EditTeacher(data, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["AllTeachers"] });
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Admin Added Successfully",
      });
      navigate("/all-teachers");
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  if (isError)
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <span className="text-primary">Something went wrong...</span>
        </div>
      </Row>
    );

  if (isLoading)
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            class="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">Loading...</span>
        </div>
      </Row>
    );
  const initialValues = {
    firstName: singleTeacher?.firstName || "",
    lastName: singleTeacher?.lastName || "",
    email: singleTeacher?.email || "",
    phone: singleTeacher?.phone || "",
    language: singleTeacher?.language || "",
    country: singleTeacher?.country || "",
    address: singleTeacher?.address || "",
    city: singleTeacher?.city || "",
    jobTitle: singleTeacher?.jobTitle || "",
    communityId: singleTeacher?.communityId || "",
    overview: singleTeacher?.overview || "",
    experience: singleTeacher?.experience
      ? singleTeacher?.experience === 0
        ? 0
        : singleTeacher?.experience
      : "",
    image: singleTeacher?.image || "",
  };

  return (
    <>
      <PageTitle
        activeMenu={"Edit Teacher"}
        motherMenu={"Teachers"}
        userId={id}
        goBack
      />
      <div className="row">
        <div className="col-xl-12 col-xxl-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Admin Info</h5>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={loginSchema}
              // validateOnBlur={true}
              // validateOnChange={true}
              onSubmit={(values, actions) => {
                const { confirmPassword, ...restVa } = {
                  ...values,
                };
                const changedValues = Object.keys(restVa).reduce((acc, key) => {
                  if (restVa[key] !== initialValues[key]) {
                    acc[key] = restVa[key]; // Include only changed values
                  }
                  return acc;
                }, {});
                mutate(changedValues, {
                  onSuccess: () => {
                    actions.resetForm();
                    actions.setSubmitting(false);
                  },
                  onError: () => {
                    actions.setSubmitting(false);
                  },
                });
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldTouched,
                setFieldValue,
                validateField,
              }) => (
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="p-5">
                        <div className="author-profile">
                          <div className="author-media">
                            <img
                              src={"urlLing/" + singleTeacher?.image}
                              alt="Profile Img"
                              onError={(e) => {
                                e.target.src = IMAGES.Avatpng1;
                              }}
                            />
                            <div
                              className="upload-link"
                              data-toggle="tooltip"
                              data-placement="right"
                              data-original-title="update">
                              <input
                                id="image"
                                name="image"
                                type="file"
                                className="update-flie"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <i className="fa fa-camera"></i>
                            </div>
                          </div>
                          {errors.image && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.image}
                            </div>
                          )}
                          <div className="author-info">
                            <h6 className="title">
                              {singleTeacher?.firstName +
                                " " +
                                singleTeacher?.lastName}
                            </h6>
                            <span>#{id}</span>
                            <span>{singleTeacher?.communityName}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="firstName">
                            First Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="firstName"
                            placeholder="Enter First Name"
                            type="text"
                            name="firstName"
                            className={`form-control ${
                              touched.firstName
                                ? errors.firstName
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.firstName && errors.firstName && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.firstName}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="lastName">
                            Last Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="lastName"
                            placeholder="Enter Last Name"
                            type="text"
                            name="lastName"
                            className={`form-control ${
                              touched.lastName
                                ? errors.lastName
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.lastName && errors.lastName && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.lastName}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="Email">
                            Email
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="Email"
                            placeholder="Email"
                            type="email"
                            name="email"
                            className={`form-control ${
                              touched.email
                                ? errors.email
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.email && errors.email && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.email}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="Phone_Number">
                            Phone Number
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="Phone_Number"
                            placeholder="Phone Number"
                            type="text"
                            name="phone"
                            className={`form-control ${
                              touched.phone
                                ? errors.phone
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.phone && errors.phone && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.phone}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div
                          className={`form-group mb-3 ${
                            values.password
                              ? errors.password
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}>
                          <label
                            className="form-label"
                            htmlFor="Password">
                            Password
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              id="Password"
                              placeholder="Password"
                              type={`${showPassword ? "text" : "password"}`}
                              maxLength="10"
                              name="password"
                              className={`form-control ${
                                touched.password
                                  ? errors.password
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div
                              className="input-group-text show-validate cursor-pointer"
                              onClick={() => setShowPassword(!showPassword)}>
                              {showPassword === false ? (
                                <i className="fa fa-eye-slash" />
                              ) : (
                                <i className="fa fa-eye" />
                              )}
                            </div>
                            {touched.password && errors.password && (
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp">
                                {errors.password}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div
                          className={`form-group mb-3 ${
                            values.confirmPassword
                              ? errors.confirmPassword
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}>
                          <label
                            className="form-label"
                            htmlFor="confirmPassword">
                            Confirm Password
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              id="confirmPassword"
                              placeholder="Confirm Password"
                              type={`${showPassword ? "text" : "password"}`}
                              maxLength="10"
                              name="confirmPassword"
                              className={`form-control ${
                                touched.confirmPassword
                                  ? errors.confirmPassword
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              value={values.confirmPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div
                              className="input-group-text show-validate cursor-pointer"
                              onClick={() => setShowPassword(!showPassword)}>
                              {showPassword === false ? (
                                <i className="fa fa-eye-slash" />
                              ) : (
                                <i className="fa fa-eye" />
                              )}
                            </div>
                            {touched.confirmPassword &&
                              errors.confirmPassword && (
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp">
                                  {errors.confirmPassword}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="form-label">
                            Language
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            name="language"
                            id="language"
                            isSearchable={false}
                            defaultValue={languages[0]}
                            options={languages}
                            styles={customStyles(
                              errors.language,
                              touched.language
                            )}
                            className={`custom-react-select ${
                              touched.language
                                ? errors.language
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={languages.find(
                              (option) => option.value === values.language
                            )}
                            onChange={(option) => {
                              setFieldValue(
                                "language",
                                option ? option.value : ""
                              );
                              setFieldTouched("language", true, false); // mark as touched but do not validate yet
                              validateField("language"); // explicitly validate the field
                            }}
                            onBlur={() => setFieldTouched("language", true)}
                          />
                          {touched.language && errors.language && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.language}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="country"
                            className="form-label">
                            Country
                          </label>
                          <Select
                            name="country"
                            id="country"
                            isSearchable={true}
                            defaultValue={COUNTRIES[0]}
                            options={COUNTRIES}
                            styles={customStyles(
                              errors.country,
                              touched.country
                            )}
                            className={`custom-react-select ${
                              touched.country
                                ? errors.country
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={COUNTRIES.find(
                              (option) => option.value === values.country
                            )}
                            onChange={(option) => {
                              setFieldValue(
                                "country",
                                option ? option.value : ""
                              );
                              setFieldTouched("country", true, false); // mark as touched but do not validate yet
                              validateField("country"); // explicitly validate the field
                            }}
                            onBlur={() => setFieldTouched("country", true)}
                          />
                          {touched.country && errors.country && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.country}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="address">
                            City
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="city"
                            placeholder="Enter City"
                            type="text"
                            name="city"
                            className={`form-control ${
                              touched.city
                                ? errors.city
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.city && errors.city && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.city}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="communityId">
                            Community Id
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="communityId"
                            placeholder="Enter CommunityId"
                            type="text"
                            name="communityId"
                            className={`form-control ${
                              touched.communityId
                                ? errors.communityId
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.communityId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.communityId && errors.communityId && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.communityId}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="jobTitle">
                            Job Title
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="jobTitle"
                            placeholder="Enter Job Title"
                            type="text"
                            name="jobTitle"
                            className={`form-control ${
                              touched.jobTitle
                                ? errors.jobTitle
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.jobTitle}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.jobTitle && errors.jobTitle && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.jobTitle}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="experience">
                            Years of Experience
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="experience"
                            placeholder="Enter The No of Experience Years"
                            type="text"
                            name="experience"
                            className={`form-control ${
                              touched.experience
                                ? errors.experience
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.experience}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.experience && errors.experience && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.experience}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="address">
                            Address
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="address"
                            placeholder="Enter Address"
                            type="text"
                            name="address"
                            className={`form-control ${
                              touched.address
                                ? errors.address
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.address && errors.address && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.address}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="communityId">
                            Overview
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="overview"
                            placeholder="Enter Overview"
                            type="text"
                            name="overview"
                            className={`form-control ${
                              touched.overview
                                ? errors.overview
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.overview}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.overview && errors.overview && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.overview}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <button
                          id={`edit-teacher-${id}`}
                          type="submit"
                          className="btn btn-primary me-1"
                          disabled={isSubmitting}>
                          {isSubmitting ? <Loader /> : "Submit"}
                        </button>
                        <button
                          onClick={() => navigate("/all-teachers")}
                          type="button"
                          className="btn btn-danger light">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTeacher;
