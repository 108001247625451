import React, { useContext } from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
/// Css
import "./../index.css";
import "./../chart.css";
import "./../step.css";

/// Layout
import Nav from "./../layouts/nav";
import Footer from "./../layouts/Footer";

import { ThemeContext } from "../../context/ThemeContext";
//Scroll To Top
import ScrollToTop from "./../layouts/ScrollToTop";

/// Dashboard
import Home from "./../pages/dashboard/Home";
import EmptyPage from "./../pages/dashboard/EmptyPage";

//Admin
import AllAdmins from "../pages/Admins/AllAdmins";
import AddAdmin from "../pages/Admins/AddAdmin";
import AboutAdmin from "../pages/Admins/AboutAdmin";
import EditAdminPage from "../pages/Admins/EditAdmin";

/// Charts
import RechartJs from "./../pages/charts/rechart";
import ChartJs from "./../pages/charts/Chartjs";
import SparklineChart from "./../pages/charts/Sparkline";
import ApexChart from "./../pages/charts/apexcharts";

/// Pages
import LockScreen from "./../pages/error/LockScreen";
import Error400 from "./../pages/error/Error400";
import Error403 from "./../pages/error/Error403";
import Error404 from "./../pages/error/Error404";
import Error500 from "./../pages/error/Error500";
import Error503 from "./../pages/error/Error503";

/// Teachers
import AllTeachers from "../pages/Teachers/AllTeachers";
import AddTeacher from "../pages/Teachers/AddTeacher";
import EditTeacher from "../pages/Teachers/EditTeacher";
import AboutTeacher from "../pages/Teachers/AboutTeacher";

/// Communities
import AllCommunities from "../pages/Communities/AllCommunities";
import AddCommunity from "../pages/Communities/AddCommunity";
import EditCommunity from "../pages/Communities/EditCommunity";
import AboutCommunity from "../pages/Communities/AboutCommunity";
import Transactions from "../pages/Transactions/Transactions";

const Markup = () => {
  const allroutes = [
    /// Dashboard
    {
      url: "",
      component: (
        <Navigate
          to="/dashboard"
          replace
        />
      ),
    },
    { url: "dashboard", component: <Home /> },

    //Admin
    { url: "all-admins", component: <AllAdmins /> },
    { url: "all-admins/add-admin", component: <AddAdmin /> },
    { url: "all-admins/edit-admin/:id", component: <EditAdminPage /> },
    { url: "all-admins/:id", component: <AboutAdmin /> },
    //Teacher
    { url: "all-teachers", component: <AllTeachers /> },
    { url: "all-teachers/add-teacher", component: <AddTeacher /> },
    { url: "all-teachers/edit-teacher/:id", component: <EditTeacher /> },
    { url: "all-teachers/:id", component: <AboutTeacher /> },
    //Community
    { url: "all-communities", component: <AllCommunities /> },
    { url: "all-communities/add-community", component: <AddCommunity /> },
    { url: "all-communities/edit-community/:id", component: <EditCommunity /> },
    {
      url: "all-communities/about-community/:id",
      component: <AboutCommunity />,
    },

    // Transactions

    { url: "all-transactions", component: <Transactions /> },
    // Announcements
    { url: "all-announcements", component: <div>Announcements</div> },

    // /// Apps
    { url: "app-profile", component: <div>In Progress</div> },

    /// pages
    { url: "empty", component: <EmptyPage /> },
  ];

  function NotFound() {
    const url = allroutes.map((route) => route.url);
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    if (url.indexOf(path) <= 0) {
      return <Error404 />;
    }
  }

  return (
    <>
      <Routes>
        <Route
          path="/page-lock-screen"
          element={<LockScreen />}
        />
        <Route
          path="/page-error-400"
          element={<Error400 />}
        />
        <Route
          path="/page-error-403"
          element={<Error403 />}
        />
        <Route
          path="/page-error-404"
          element={<Error404 />}
        />
        <Route
          path="/page-error-500"
          element={<Error500 />}
        />
        <Route
          path="/page-error-503"
          element={<Error503 />}
        />
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
        <Route
          path="*"
          element={<NotFound />}
        />
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { sidebariconHover } = useContext(ThemeContext);
  const sideMenu = useSelector((state) => state.sideMenu);
  return (
    <>
      <div
        id="main-wrapper"
        className={`show  ${sidebariconHover ? "iconhover-toggle" : ""} ${
          sideMenu ? "menu-toggle" : ""
        }`}>
        <Nav />
        <div className="content-body">
          <div
            className="container-fluid"
            style={{ minHeight: window.screen.height - 45 }}>
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Markup;
