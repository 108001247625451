import React, { useState, useRef } from "react";
import { Dropdown, Row, Tab, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import PageTitle from "../../layouts/PageTitle";
import { DatePicker } from "rsuite";
import { useQuery } from "@tanstack/react-query";
import { IMAGES } from "../../constant/theme";
import Select from "react-select";
import { STATUS, TRANSACTIONTYPE } from "../../../util/Selects";
import { GetTransactions } from "../../../API/Transactions/GetTransactions";
import Swal from "sweetalert2";

const theadData = [
  { heading: "Trans Id.", sortingVale: "rollno" },
  { heading: "Type", sortingVale: "name" },
  // { heading: "Status", sortingVale: "status" },
  { heading: "Status", sortingVale: "email" },
  { heading: "Amount", sortingVale: "mobile" },
  { heading: "Date", sortingVale: "join" },
  { heading: "Community Id", sortingVale: "verified" },
  { heading: "Community Name", sortingVale: "action" },
];

const Transactions = () => {
  const [localSearch, setLocalSearch] = useState("");
  const [localDate, setLocalDate] = useState("");
  const [localStatus, setLocalStatus] = useState("");
  const [localType, setLocalType] = useState("");
  const [sort, setSortata] = useState(10);
  const [search, setSearch] = useState("");
  const [date, setDate] = useState("");
  const [pageNo, setPageNo] = useState(0);
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [open, setOpen] = useState(true);

  const triggerSearch = () => {
    setSearch(localSearch);
    setDate(localDate);
    setStatus(localStatus);
    setType(localType);
  };

  const {
    data: allTransactions,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["allTransactions", search, pageNo, date, sort, type, status],
    queryFn: () => GetTransactions(search, date, type, status, pageNo, sort),
  });
  const activePag = useRef(0);

  if (isError) {
    error.response.data.error === "In valid email or password" &&
      error.response.status === 403 &&
      Swal.fire({
        title: "Opps",
        text: "You must login again!",
        icon: "warning",
        confirmButtonColor: "#df0d0d",
        confirmButtonText: "Navigate to Login",
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("token");
          // navigate("/login");
          window.location.reload();
        }
      });
    return;
  }
  if (
    isError &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        {" "}
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          Something went wrong
        </div>
      </Row>
    );
  }
  if (isLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            class="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">Loading...</span>
        </div>
      </Row>
    );
  }

  function resetFilters() {
    setStatus("");
    setType("");
    setSearch("");
    setDate("");
    setLocalStatus("");
    setLocalDate("");
    setLocalSearch("");
    setLocalType("");
  }

  const handleStatusChange = (selectedOption) => {
    setLocalStatus(selectedOption.value); // Update local state
  };
  const handleTypeChange = (selectedOption) => {
    setLocalType(selectedOption.value); // Update local state
  };

  function formatDate(dateInput) {
    const date = new Date(dateInput);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Add leading zero and slice last 2 digits
    const day = ("0" + date.getDate()).slice(-2); // Add leading zero and slice last 2 digits

    return `${year}-${month}-${day}`;
  }

  let paggination =
    Array(Math.ceil(allTransactions?.totalElements / sort))
      .fill()
      .map((_, i) => i + 1) || [];

  return (
    <>
      <PageTitle
        activeMenu={"All Transactions"}
        motherMenu={"Transactions"}
      />

      <div className="filter cm-content-box box-primary">
        <div
          className="content-title"
          onClick={() => setOpen(!open)}>
          <div className="cpa">
            <i className="fas fa-filter me-2"></i>Filter
          </div>
          <div className="tools">
            <Link
              to={"#"}
              className={`SlideToolHeader ${open ? "collapse" : "expand"}`}>
              <i className="fas fa-angle-up"></i>
            </Link>
          </div>
        </div>

        <Collapse in={open}>
          <div className="cm-content-body form excerpt">
            <div className="card-body">
              <div className="row filter-row">
                <div className="col-xl-3 col-sm-6">
                  <label className="form-label">Search</label>
                  <input
                    onChange={(e) => setLocalSearch(e.target.value)}
                    value={localSearch}
                    type="text"
                    className="form-control mb-xl-0 mb-3"
                    id="exampleFormControlInput1"
                    placeholder="Search By Transaction ID"
                  />
                </div>
                <div className="col-xl-3 col-sm-6">
                  <label className="form-label">Status</label>
                  <Select
                    name="status"
                    id="status"
                    className="z-3"
                    defaultValue={STATUS[0]}
                    options={STATUS}
                    isSearchable={false}
                    onChange={handleStatusChange}
                    value={
                      STATUS.find((opt) => opt.value === localStatus) ||
                      localStatus
                    }
                  />
                </div>
                <div className="col-xl-3 col-sm-6">
                  <label className="form-label">Type</label>
                  <Select
                    name="type"
                    id="type"
                    defaultValue={TRANSACTIONTYPE[0]}
                    options={TRANSACTIONTYPE}
                    className="z-3"
                    isSearchable={false}
                    onChange={handleTypeChange}
                    value={
                      TRANSACTIONTYPE.find((opt) => opt.value === localType) ||
                      localType
                    }
                  />
                </div>
                <div className="col-xl-3 col-sm-6 d-flex flex-column">
                  <label className="form-label">Register Date</label>
                  <DatePicker
                    placeholder="Register Date"
                    className="picker-suit z-2"
                    onChange={(e) => setLocalDate(formatDate(e))}
                    value={localDate ? new Date(localDate) : null}
                    format="yyyy-MM-dd"
                  />
                </div>
                <div className="col-xl-3 col-sm-6 align-self-end mt-4">
                  <button
                    onClick={triggerSearch}
                    // onClick={() => DataSearch(search || date)}
                    className="btn btn-primary rounded-sm me-2"
                    title="Click here to Search"
                    type="button">
                    <i className="fa fa-search me-1" />
                    Filter
                  </button>
                  <button
                    onClick={resetFilters}
                    className="btn btn-danger rounded-sm light"
                    title="Click here to remove filter"
                    type="button">
                    Remove Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>

      <Row>
        <Tab.Container defaultActiveKey={"List"}>
          <div className="col-lg-12">
            <Tab.Content className="row tab-content">
              <Tab.Pane
                eventKey="List"
                className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">All Transactions List </h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div
                        id="holidayList"
                        className="dataTables_wrapper no-footer">
                        <div className="justify-content-between d-sm-flex">
                          <div className="dataTables_length">
                            <label className="d-flex align-items-center">
                              Show
                              <Dropdown className="search-drop">
                                <Dropdown.Toggle
                                  as="div"
                                  className="search-drop-btn">
                                  {sort}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => setSortata("10")}>
                                    10
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => setSortata("20")}>
                                    20
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => setSortata("30")}>
                                    30
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                              entries
                            </label>
                          </div>
                        </div>
                        <table
                          id="example4"
                          className="display dataTable no-footer w-100">
                          <thead>
                            <tr>
                              {theadData.map((item, ind) => (
                                <th key={ind}>{item.heading}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {!isError &&
                              allTransactions.content.map((data, ind) => (
                                <tr key={ind}>
                                  <td>
                                    <strong>{data.transactionId}</strong>
                                  </td>
                                  <td>
                                    <Link to={"#"}>
                                      <strong>{data.type}</strong>
                                    </Link>
                                  </td>
                                  <td>
                                    <Link to={"#"}>
                                      {data.status === "APPROVED" && (
                                        <Badge
                                          bg=""
                                          className={"badge-success light"}>
                                          {data.status}
                                        </Badge>
                                      )}
                                      {data.status === "DECLINED" && (
                                        <Badge
                                          bg=""
                                          className={"badge-danger light"}>
                                          {data.status}
                                        </Badge>
                                      )}
                                      {data.status === "PENDING" && (
                                        <Badge
                                          bg=""
                                          className={"badge-warning light"}>
                                          {data.status}
                                        </Badge>
                                      )}
                                    </Link>
                                  </td>
                                  <td>
                                    <Link to={"#"}>
                                      <strong>{data.amount}$</strong>
                                    </Link>
                                  </td>
                                  <td>{new Date(data.date).toDateString()}</td>
                                  <td>#{data.communityId}</td>
                                  <td>
                                    <img
                                      className="rounded-circle me-2"
                                      width="35"
                                      src={data.communityImage}
                                      alt="Person"
                                      onError={(e) => {
                                        e.target.src = IMAGES.Avatpng1;
                                      }}
                                    />
                                    {data.communityName}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                          <div className="dataTables_info">
                            Showing {pageNo * sort + 1} to{" "}
                            {pageNo < allTransactions.totalPages - 1
                              ? allTransactions?.size * (pageNo + 1)
                              : allTransactions?.totalElements}{" "}
                            of {allTransactions?.totalElements} entries
                          </div>
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="example5_paginate">
                            <Link
                              className="paginate_button previous disabled"
                              to="#"
                              onClick={() => {
                                if (pageNo > 0) setPageNo(pageNo - 1);
                              }}>
                              Previous
                            </Link>
                            <span>
                              {paggination.map((number, i) => (
                                <Link
                                  key={i}
                                  to="#"
                                  className={`paginate_button  ${
                                    pageNo === i ? "current" : ""
                                  } `}
                                  onClick={() => setPageNo(i)}>
                                  {number}
                                </Link>
                              ))}
                            </span>
                            <Link
                              className="paginate_button next"
                              to="#"
                              onClick={() => {
                                if (pageNo < paggination.length - 1)
                                  setPageNo(pageNo + 1);
                              }}>
                              Next
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </Row>
    </>
  );
};

export default Transactions;
