import { Tab, Nav } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import { Row, Col, Card, ProgressBar, Button, Badge } from "react-bootstrap";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

//Import Components
import { BgCard } from "../../elements/CardDesign";
import { useMutation, useQuery } from "@tanstack/react-query";

import { GetCommunityDashboardData } from "../../../API/Communities/GetCommunityDashboardData";
import { IMAGES } from "../../constant/theme";
import Swal from "sweetalert2";
import { DeleteCommunity } from "../../../API/Communities/DeleteCommunity";
import { queryClient } from "../../../App";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

// import DualArea from "../charts/Chartjs/dualArea";

//Charts
const DonughtChart = loadable(() =>
  pMinDelay(import("../../elements/dashboard/DonughtChart"), 500)
);
const DualArea = loadable(() =>
  pMinDelay(import("../charts/Chartjs/dualArea"), 500)
);

const generatePDF = (data) => {
  const doc = new jsPDF();

  // Add a logo if needed
  // doc.addImage('path/to/logo.png', 'PNG', 15, 10, 20, 20);

  // Title and subtitle
  doc.setFontSize(24);
  doc.text(data.title, 14, 30);
  doc.setFontSize(16);
  doc.text("Detailed Community Report", 14, 40);

  // General Info
  doc.setFontSize(12);
  doc.text(`Manager Name: ${data.managerName || "N/A"}`, 14, 55);
  doc.text(`Total Students: ${data.studentNo} / ${data.maxStudentNo}`, 14, 65);
  doc.text(`Courses: ${data.coursesNo}`, 14, 75);
  doc.text(`Income: $${data.income}`, 14, 85);

  // Top Courses Enrollments
  doc.text("Top Courses by Enrollments", 14, 100);
  autoTable(doc, {
    startY: 110,
    theme: "striped",
    head: [["Course", "Enrollments"]],
    body: Object.entries(data.topCoursesEnrolls).map(([course, count]) => [
      course,
      count,
    ]),
    margin: { left: 14 },
  });

  // Top Courses Income
  doc.text("Top Courses by Income", 14, doc.lastAutoTable.finalY + 10);
  autoTable(doc, {
    startY: doc.lastAutoTable.finalY + 20,
    theme: "grid",
    head: [["Course", "Income"]],
    body: Object.entries(data.topCoursesIncome).map(([course, income]) => [
      course,
      `$${income}`,
    ]),
    margin: { left: 14 },
  });

  // Students
  doc.text("Student List", 14, doc.lastAutoTable.finalY + 10);
  autoTable(doc, {
    startY: doc.lastAutoTable.finalY + 20,
    theme: "plain",
    head: [["ID", "Name", "Email", "Registered"]],
    body: data.students.map((student) => [
      student.id,
      `${student.firstName} ${student.lastName}`,
      student.email,
      student.registerDate.split("T")[0],
    ]),
    margin: { left: 14 },
  });

  // Teachers
  doc.text("Teacher List", 14, doc.lastAutoTable.finalY + 10);
  autoTable(doc, {
    startY: doc.lastAutoTable.finalY + 20,
    theme: "plain",
    head: [["ID", "Name", "Email", "Registered"]],
    body: data.teachers.map((teacher) => [
      teacher.id,
      `${teacher.firstName} ${teacher.lastName}`,
      teacher.email,
      teacher.registerDate.split("T")[0],
    ]),
    margin: { left: 14 },
  });

  // Save the PDF
  doc.save(`${data.title}_Report.pdf`);
};

const theadData = [
  { heading: "ID.", sortingVale: "rollno" },
  { heading: "Name", sortingVale: "name" },
  { heading: "Status", sortingVale: "status" },
  { heading: "Email", sortingVale: "email" },
  { heading: "Mobile", sortingVale: "mobile" },
  { heading: "Community", sortingVale: "mobile" },
  { heading: "Verified", sortingVale: "verified" },
  { heading: "Registration Date", sortingVale: "join" },
];

const theadDataStudents = [
  { heading: "ID.", sortingVale: "rollno" },
  { heading: "Name", sortingVale: "name" },
  { heading: "Status", sortingVale: "status" },
  { heading: "Email", sortingVale: "email" },
  { heading: "Mobile", sortingVale: "mobile" },
  { heading: "Education Level", sortingVale: "Education" },
  { heading: "Verified", sortingVale: "verified" },
  { heading: "Registration Date", sortingVale: "join" },
];

const CommunityDashboard = ({ id }) => {
  const navigate = useNavigate();
  const { isLoading, data: communityDashboard } = useQuery({
    queryKey: ["communityDashboard", id],
    queryFn: () => GetCommunityDashboardData(id),
  });

  const { mutate } = useMutation({
    mutationFn: (id) => DeleteCommunity(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["AllCommunities"] });
      navigate("/all-communities");
    },
    onError: () => {
      Swal.fire("Error!", "Something went wrong.", "error");
    },
  });
  function deleteCommunity(id) {
    Swal.fire({
      title: "Are you sure to you want to delete this community ?",
      text: "You will not be able to recover this imaginary file !!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df0d0d",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        mutate(id);
        Swal.fire(
          "Deleted!",
          ` Community with id #${id} has been deleted successfully.`,
          "success"
        );
      }
    });
  }

  if (isLoading)
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            class="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">Loading...</span>
        </div>
      </Row>
    );
  const bgCarddBlog = isLoading
    ? []
    : [
        {
          title: "Community",
          number: communityDashboard?.coursesNo,
          icon: <i className="la la-graduation-cap" />,
          color: "primary",
          maxCapacity: 1000,
          unit: "Courses",
        },
        {
          title: "Users",
          number: communityDashboard?.studentNo,
          icon: <i className="la la-users" />,
          color: "secondary",
          maxCapacity: communityDashboard?.maxStudentNo,
          unit: "Users",
        },
        {
          title: "Courses",
          number: communityDashboard?.usedStorage / 1024,
          icon: <i className="la la-dollar" />,
          color: "warning",
          maxCapacity: communityDashboard?.maxStorage / 1024,
          unit: "GB",
        },
        {
          title: "Income",
          number: communityDashboard?.balance,
          icon: <i class="la la-hdd"></i>,
          color: "danger",
          maxCapacity: communityDashboard?.income,
          unit: "EGP",
        },
      ];
  const HighestCoursesEnrolls = communityDashboard?.topCoursesEnrolls;
  const HighestCoursesIncome = communityDashboard?.topCoursesIncome;
  let HighestCoursesEnrollsKeys = [];
  let HighestCoursesEnrollsValues = [];
  let HighestCoursesIncomeValues = [];
  let HighestCoursesIncomeKeys = [];
  let RestCourses = 0;
  let RestIncome = 0;

  HighestCoursesEnrollsKeys = !isLoading && Object.keys(HighestCoursesEnrolls);
  HighestCoursesEnrollsValues =
    !isLoading && Object.values(HighestCoursesEnrolls);

  HighestCoursesIncomeValues =
    !isLoading && Object.values(HighestCoursesIncome);
  HighestCoursesIncomeKeys = !isLoading && Object.keys(HighestCoursesIncome);
  // let CommunityProgress = communityDashboard?.communityProgress;
  let IncomeProgress = communityDashboard?.incomeProgress;
  let StudentProgress = communityDashboard?.studentProgress;

  RestCourses =
    !isLoading &&
    communityDashboard?.studentNo -
      HighestCoursesEnrollsValues.reduce((a, b) => a + b, 0);

  RestIncome =
    !isLoading &&
    communityDashboard?.income -
      HighestCoursesIncomeValues.reduce((a, b) => a + b, 0);
  return (
    <>
      <Row>
        {bgCarddBlog.map((item, index) => (
          <Col
            xl={"3"}
            xxl={"3"}
            sm={"6"}
            key={index}>
            <div className={`widget-stat card bg-${item.color}`}>
              <div className="card-body">
                <BgCard
                  title={item.title}
                  number={
                    item.title === "Income" ? item.number + " EGP" : item.number
                  }
                  percent={
                    ((item.number / item.maxCapacity) * 100).toFixed(2) + "%"
                  }
                  color={item.color}
                  icon={item.icon}
                  maxCapacity={item.maxCapacity}
                  unit={item.unit}
                />
              </div>
            </div>
          </Col>
        ))}
        <Col
          xl={"6"}
          xxl={"6"}
          sm={"6"}>
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Highest Courses Enrollment</h3>
            </div>
            <div className="card-body d-flex justify-content-center flex-wrap xl:justify-content-center  gap-4">
              <DonughtChart
                data={HighestCoursesEnrollsKeys}
                values={HighestCoursesEnrollsValues}
                total={RestCourses}
              />
              <div className="doughnut-chart__content col-6 d-flex flex-column gap-4">
                <h3>Top 3 Community</h3>

                <div className="d-flex justify-content-between gap-3">
                  <div className="d-flex flex-column gap-2 col-6">
                    <div className="text-center">
                      <h6>{HighestCoursesEnrollsKeys[0]}</h6>
                      <div className="d-flex align-items-center gap-2">
                        <div className="">
                          {HighestCoursesEnrollsValues.length > 0
                            ? (
                                (HighestCoursesEnrollsValues[0] /
                                  communityDashboard?.studentNo) *
                                100
                              ).toFixed(1)
                            : 0}
                          %
                        </div>
                        <ProgressBar
                          className="flex-grow-1"
                          now={
                            HighestCoursesEnrollsValues.length > 0
                              ? (HighestCoursesEnrollsValues[0] /
                                  communityDashboard?.studentNo) *
                                100
                              : 0
                          }
                          variant="warning"
                        />
                      </div>
                    </div>
                    <div className="text-center">
                      <h6>{HighestCoursesEnrollsKeys[1]}</h6>
                      <div className="d-flex align-items-center gap-2 ">
                        <div className="">
                          {HighestCoursesEnrollsValues.length > 0
                            ? (
                                (HighestCoursesEnrollsValues[1] /
                                  communityDashboard?.studentNo) *
                                100
                              ).toFixed(1)
                            : 0}
                          %
                        </div>
                        <ProgressBar
                          className="flex-grow-1"
                          now={
                            HighestCoursesEnrollsValues.length > 0
                              ? (HighestCoursesEnrollsValues[1] /
                                  communityDashboard?.studentNo) *
                                100
                              : 0
                          }
                          variant="danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-2 col-6">
                    <div className="text-center">
                      <h6>Others</h6>
                      <div className="d-flex align-items-center gap-2">
                        <div className="">
                          {" "}
                          {RestCourses > 0
                            ? (
                                (RestCourses / communityDashboard?.studentNo) *
                                100
                              ).toFixed(1)
                            : 0}
                          %
                        </div>
                        <ProgressBar
                          className="flex-grow-1"
                          now={
                            RestCourses > 0
                              ? (RestCourses / communityDashboard?.studentNo) *
                                100
                              : 0
                          }
                          variant="secondary"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col
          xl={"6"}
          xxl={"6"}
          sm={"6"}>
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Highest Courses Income</h3>
            </div>
            <div className="card-body d-flex justify-content-center flex-wrap xl:justify-content-center  gap-4">
              <DonughtChart
                data={HighestCoursesIncomeKeys}
                values={HighestCoursesIncomeValues}
                total={RestIncome}
              />
              <div className="doughnut-chart__content col-6 d-flex flex-column gap-4">
                <h3>Top 3 Community</h3>

                <div className="d-flex justify-content-between gap-3">
                  <div className="d-flex flex-column gap-2 col-6">
                    <div className="text-center">
                      <h6>{HighestCoursesIncomeKeys[0]}</h6>
                      <div className="d-flex align-items-center gap-2">
                        <div className="">
                          {HighestCoursesIncomeValues.length > 0
                            ? (
                                (HighestCoursesIncomeValues[0] /
                                  communityDashboard?.income) *
                                100
                              ).toFixed(1)
                            : 0}
                          %
                        </div>
                        <ProgressBar
                          className="flex-grow-1"
                          now={
                            HighestCoursesIncomeValues.length > 0
                              ? (HighestCoursesIncomeValues[0] /
                                  communityDashboard?.income) *
                                100
                              : 0
                          }
                          variant="warning"
                        />
                      </div>
                    </div>
                    <div className="text-center">
                      <h6>{HighestCoursesIncomeKeys[1]}</h6>
                      <div className="d-flex align-items-center gap-2 ">
                        <div className="">
                          {HighestCoursesIncomeValues.length > 0
                            ? (
                                (HighestCoursesIncomeValues[1] /
                                  communityDashboard?.income) *
                                100
                              ).toFixed(1)
                            : 0}
                          %
                        </div>
                        <ProgressBar
                          className="flex-grow-1"
                          now={
                            HighestCoursesIncomeValues.length > 0
                              ? (HighestCoursesIncomeValues[1] /
                                  communityDashboard?.income) *
                                100
                              : 0
                          }
                          variant="danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-2 col-6">
                    <div className="text-center">
                      <h6>Others</h6>
                      <div className="d-flex align-items-center gap-2">
                        <div className="">
                          {RestIncome > 0
                            ? (
                                (RestIncome / communityDashboard?.income) *
                                100
                              ).toFixed(1)
                            : 0}
                          %
                        </div>
                        <ProgressBar
                          className="flex-grow-1"
                          now={
                            RestIncome > 0
                              ? (RestIncome / communityDashboard?.income) * 100
                              : 0
                          }
                          variant="secondary"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={"12"}>
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <Tab.Container defaultActiveKey={"Personal"}>
                    <Nav
                      as="ul"
                      className="nav nav-tabs d-flex justify-content-between pb-3">
                      <Nav.Item as="li">
                        <h3
                          className="card-title"
                          eventKey="Overview">
                          Overview
                        </h3>
                      </Nav.Item>

                      <div className="d-flex gap-2 align-items-center">
                        <Link
                          id={`edit-community-${id}`}
                          to={"/all-communities/edit-community/" + id}
                          className="btn sharp btn-primary px-4">
                          <i className="fa fa-pencil" /> Edit
                        </Link>
                        <Link
                          id={`delete-community-${id}`}
                          onClick={() => deleteCommunity(id)}
                          className="btn sharp btn-danger px-3">
                          <i className="fa fa-trash" /> Delete
                        </Link>
                      </div>
                    </Nav>
                    <Tab.Content className="tab-content">
                      <div className="d-flex justify-content-between flex-md-row flex-column gap-xsm-1 gap-md-4 gap-3">
                        <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12 pt-4 ">
                          <div className="">
                            <div className="card overflow-hidden">
                              <div
                                className="text-center p-3 overlay-box"
                                // style={{
                                //   backgroundImage: `url(${IMAGES.BigImg1})`,
                                // }}
                              >
                                <div className="profile-photo">
                                  <img
                                    src={"url/" + communityDashboard.logo}
                                    width="100"
                                    className="img-fluid rounded-circle"
                                    alt=""
                                    onError={(e) => {
                                      e.target.src = IMAGES.Avatpng1;
                                    }}
                                  />
                                </div>
                                <p className="text-white mb-0">
                                  {communityDashboard.title}
                                </p>
                              </div>
                              <ul className="list-group list-group-flush bg-light">
                                <li className="list-group-item d-flex justify-content-between border-dark  py-3">
                                  <span className="mb-1">
                                    #{communityDashboard.id}
                                  </span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between border-dark  py-3">
                                  <span className="mb-1">
                                    {communityDashboard.title}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-9 col-lg-7 col-md-7 col-sm-12 mt-4 bg-light rounded p-4">
                          <div className="profile-personal-info">
                            <h4 className="text-primary mb-4">
                              About {communityDashboard.title}
                            </h4>

                            <div className="row mb-4">
                              <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                <h5 className="f-w-500">
                                  Name
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                                <span>{communityDashboard.title}</span>
                              </div>
                            </div>
                            <div className="row mb-4">
                              <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                <h5 className="f-w-500">
                                  Allowed Space
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                                <span>
                                  {communityDashboard.maxStorage / 1024}
                                </span>
                              </div>
                            </div>
                            <div className="row mb-4">
                              <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                <h5 className="f-w-500">
                                  Used Space
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                                <span>
                                  {communityDashboard.usedStorage / 1024}
                                </span>
                              </div>
                            </div>
                            <div className="row mb-4">
                              <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                <h5 className="f-w-500">
                                  Allowed Students
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                                <span>{communityDashboard.maxStudentNo}</span>
                              </div>
                            </div>
                            <div className="row mb-4">
                              <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                <h5 className="f-w-500">
                                  Used Students
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                                <span>{communityDashboard.studentNo}</span>
                              </div>
                            </div>
                            <div className="row mb-4">
                              <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                <h5 className="f-w-500">
                                  Status
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                                <span>
                                  {communityDashboard.active
                                    ? "Active"
                                    : "Not Active"}
                                </span>
                              </div>
                            </div>
                            <div className="row mb-4">
                              <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                <h5 className="f-w-500">
                                  Register Date
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
                                <span>
                                  {new Date(
                                    communityDashboard.registerDate
                                  ).toLocaleString()}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        {/* <div className="row">
          <div className=" col-lg-12">
            <div className="row">
              <div className="col-12"> */}
        <Col lg="12">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <div className="profile-tab">
                  <div className="custom-tab-1">
                    <Tab.Container defaultActiveKey={"Teachers"}>
                      <Nav
                        as="ul"
                        className="nav nav-tabs d-flex">
                        <Nav.Item as="li">
                          <Nav.Link eventKey="Teachers">Teachers</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link eventKey="Students">Students</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content className="tab-content">
                        <Tab.Pane eventKey={"Teachers"}>
                          <table
                            id="example4"
                            className="display dataTable no-footer w-100">
                            <thead>
                              <tr>
                                {theadData.map((item, ind) => (
                                  <th key={ind}>{item.heading}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {!communityDashboard ||
                              communityDashboard.teachers.length === 0 ? (
                                <p className="pt-5">No data found</p>
                              ) : (
                                communityDashboard.teachers.map((data, ind) => (
                                  <tr key={ind}>
                                    <td>
                                      <strong>{data.id}</strong>
                                    </td>
                                    <td>
                                      <img
                                        className="rounded-circle me-2"
                                        width="35"
                                        src={data.image}
                                        alt="Person"
                                        onError={(e) => {
                                          e.target.src = IMAGES.Avatpng1;
                                        }}
                                      />
                                      {data.firstName + " " + data.lastName}
                                    </td>
                                    <td>
                                      <Badge
                                        bg=""
                                        className={
                                          data.active
                                            ? `badge-success light`
                                            : `badge-danger light`
                                        }>
                                        {data.active ? "Active" : "Inactive"}
                                      </Badge>
                                    </td>
                                    <td>
                                      <Link to={"#"}>
                                        <strong>{data.email}</strong>
                                      </Link>
                                    </td>
                                    <td>
                                      <Link to={"#"}>
                                        <strong>+{data.phone}</strong>
                                      </Link>
                                    </td>
                                    <td>
                                      <img
                                        className="rounded-circle me-2"
                                        width="35"
                                        src={data.communityLogo}
                                        alt="Person"
                                        onError={(e) => {
                                          e.target.src = IMAGES.Avatpng1;
                                        }}
                                      />
                                      {data.communityName}
                                    </td>

                                    <td>
                                      {data.verified === true ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          fill="#7ED321"
                                          class="bi bi-check-circle-fill"
                                          viewBox="0 0 16 16">
                                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          fill="#FF1616"
                                          class="bi bi-x-circle-fill"
                                          viewBox="0 0 16 16">
                                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                                        </svg>
                                      )}
                                    </td>

                                    <td>
                                      {new Date(
                                        data.registerDate
                                      ).toDateString()}
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </Tab.Pane>
                      </Tab.Content>
                      <Tab.Content className="tab-content">
                        <Tab.Pane eventKey={"Students"}>
                          <table
                            id="example4"
                            className="display dataTable no-footer w-100">
                            <thead>
                              <tr>
                                {theadDataStudents.map((item, ind) => (
                                  <th key={ind}>{item.heading}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {!communityDashboard ||
                              communityDashboard.students.length === 0 ? (
                                <p className="pt-5">No data found</p>
                              ) : (
                                communityDashboard.students.map((data, ind) => (
                                  <tr key={ind}>
                                    <td>
                                      <strong>{data.id}</strong>
                                    </td>
                                    <td>
                                      <img
                                        className="rounded-circle me-2"
                                        width="35"
                                        src={data.image}
                                        alt="Person"
                                        onError={(e) => {
                                          e.target.src = IMAGES.Avatpng1;
                                        }}
                                      />
                                      {data.firstName + " " + data.lastName}
                                    </td>
                                    <td>
                                      <Badge
                                        bg=""
                                        className={
                                          data.active
                                            ? `badge-success light`
                                            : `badge-danger light`
                                        }>
                                        {data.active ? "Active" : "Inactive"}
                                      </Badge>
                                    </td>
                                    <td>
                                      <Link to={"#"}>
                                        <strong>{data.email}</strong>
                                      </Link>
                                    </td>
                                    <td>
                                      <Link to={"#"}>
                                        <strong>+{data.phone}</strong>
                                      </Link>
                                    </td>
                                    <td>{data.eduLevel}</td>

                                    <td>
                                      {data.verified === true ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          fill="#7ED321"
                                          class="bi bi-check-circle-fill"
                                          viewBox="0 0 16 16">
                                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          fill="#FF1616"
                                          class="bi bi-x-circle-fill"
                                          viewBox="0 0 16 16">
                                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                                        </svg>
                                      )}
                                    </td>

                                    <td>
                                      {new Date(
                                        data.registerDate
                                      ).toDateString()}
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        {/* </div>
            </div>
          </div>
        </div> */}
      </Row>
      <Row>
        <Col
          xxl={"12"}
          xl={"12"}
          sm={"12"}
          lg={"12"}>
          <div className="card h-max">
            <div className="card-header">
              <h4 className="card-title">Dual Area Chart</h4>
              <Card>
                <Button onClick={() => generatePDF(communityDashboard)}>
                  Generate Report
                </Button>
              </Card>
            </div>
            <div className="card-body ">
              <DualArea data={[IncomeProgress, StudentProgress]} />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

const AboutCommunity = () => {
  const { id } = useParams();
  return (
    <>
      <PageTitle
        activeMenu={"All Communities"}
        motherMenu={"Communities"}
        userId={id}
      />
      <CommunityDashboard id={id} />
    </>
  );
};

export default AboutCommunity;
