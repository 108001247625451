import axiosInstance from "../../services/AxiosInstance";

export async function SuspednTeacher(id) {
  // /admin-api/teachers/47/status-switch
  return axiosInstance
    .get(`admin-api/teachers/${id}/status-switch`)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error suspending teacher:", error);
      throw error; // Rethrow to be caught by React Query error handling
    });
}
