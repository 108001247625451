import axiosInstance from "../../services/AxiosInstance";

export async function GetSingleTeacher(id) {
  return axiosInstance
    .get("admin-api/teachers/" + id)
    .then((response) => response.data) // Return directly the data part of the response
    .catch((error) => {
      console.error("Error fetching teachers:", error);
      throw error; // Rethrow to be caught by React Query error handling
    });
}
