import { Formik } from "formik";
import PageTitle from "../../layouts/PageTitle";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { Loader } from "rsuite";
import Swal from "sweetalert2";
import { queryClient } from "../../../App";
import { useNavigate } from "react-router-dom";
import { AddNewCommunity } from "../../../API/Communities/AddNewCommunity";
import { UploadToS3 } from "../../../util/UploadToS3";

const loginSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, "Community title must consist of at least 3 characters ")
    .max(50, "Community title must consist of maximum 50 characters ")
    .matches(
      /^[a-zA-Z0-9_-]*$/,
      "Title must be alphanumeric and can include underscores and dashes"
    )
    .required("Please enter a community title"),
  managerName: Yup.string()
    .min(3, "Community manager name must consist of at least 3 characters ")
    .max(50, "Community manager name must consist of maximum 50 characters ")
    .required("Please enter a Community manager name"),
  maxStudentNo: Yup.number()
    .typeError("Max students must be a number")
    .positive("Max students number must be a positive number")
    .test(
      "isAtLeastTwoDigits",
      "Max students number must be at least 2 digits",
      (number) => number && number.toString().length >= 2
    ),
  maxStorage: Yup.number()
    .typeError("Max storage must be a number")
    .positive("Max storage number must be a positive number")
    .min(20, "Max storage number must be at least 20GB")
    .test(
      "isAtLeastTwoDigits",
      "Max storage number must be at least 2 digits",
      (number) => number && number.toString().length >= 2
    ),
  levels: Yup.array()
    .of(Yup.string().required("Level cannot be empty"))
    .min(1, "At least one level is required")
    .required("Please provide levels"),
  image: Yup.mixed()
    .required("An image file is required")
    .test(
      "fileSize",
      "The file is too large",
      (value) => value && value.size <= 1024 * 1024 * 5
    ) // 5 MB limit
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) =>
        value && ["image/jpeg", "image/png", "image/gif"].includes(value.type)
    ),
});

const AddCommunity = () => {
  const navigate = useNavigate();
  const { mutate } = useMutation({
    mutationFn: (data) => AddNewCommunity(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["AllCommunities"] });
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Admin Added Successfully",
      });
      navigate("/all-communities");
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    },
  });

  return (
    <>
      <PageTitle
        activeMenu={"Add Community"}
        motherMenu={"Communities"}
        goBack
      />
      <div className="row">
        <div className="col-xl-12 col-xxl-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Community Info</h5>
            </div>
            <Formik
              initialValues={{
                title: "",
                managerName: "",
                maxStudentNo: "",
                maxStorage: "",
                levels: [],
                levelInput: "",
                image: null,
              }}
              validationSchema={loginSchema}
              // validateOnBlur={true}
              // validateOnChange={true}
              onSubmit={(values, actions) => {
                const { image, ...restData } = values;
                mutate(restData, {
                  onSuccess: (data) => {
                    actions.resetForm();
                    actions.setSubmitting(false);
                    UploadToS3(
                      data.logo,
                      image,
                      "LMST/ADMIN/IMAGES/COMMUNITY/"
                    );
                  },
                  onError: () => {
                    actions.setSubmitting(false);
                  },
                });
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="title">
                            Community Title
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="title"
                            placeholder="Enter Community Title"
                            type="text"
                            name="title"
                            className={`form-control ${
                              touched.title
                                ? errors.title
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.title && errors.title && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.title}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="managerName">
                            Manager Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="managerName"
                            placeholder="Enter Manager Name"
                            type="text"
                            name="managerName"
                            className={`form-control ${
                              touched.managerName
                                ? errors.managerName
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.managerName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.managerName && errors.managerName && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.managerName}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="maxStudentNo">
                            Max Student No
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="maxStudentNo"
                            placeholder="Enter Max Student No"
                            type="text"
                            name="maxStudentNo"
                            className={`form-control ${
                              touched.maxStudentNo
                                ? errors.maxStudentNo
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.maxStudentNo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.maxStudentNo && errors.maxStudentNo && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.maxStudentNo}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="maxStorage">
                            Max Storage
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="maxStorage"
                            placeholder="Enter Max Storage"
                            type="text"
                            name="maxStorage"
                            className={`form-control ${
                              touched.maxStorage
                                ? errors.maxStorage
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.maxStorage}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.maxStorage && errors.maxStorage && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.maxStorage}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="levels">
                            Levels
                          </label>
                          <div className="d-flex flex-wrap">
                            <input
                              className={`form-control ${
                                touched.levelInput
                                  ? errors.levels
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              id="levelInput"
                              type="text"
                              name="levelInput"
                              placeholder="Enter Level"
                              value={values.levelInput}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  if (
                                    values.levelInput.trim() !== "" &&
                                    !values.levels.includes(
                                      values.levelInput.trim()
                                    )
                                  ) {
                                    // Only add if the input is not empty and the level is not already in the array
                                    const newLevels = [
                                      ...values.levels,
                                      values.levelInput.trim(),
                                    ];
                                    setFieldValue("levels", newLevels);
                                    setFieldValue("levelInput", ""); // Clear the input after adding
                                  }
                                }
                              }}
                            />
                            <ul className="d-flex gap-2">
                              {values.levels.map((level, index) => (
                                <li
                                  key={index}
                                  className="d-flex align-items-center gap-1 bg-light text-primary px-2 mt-2 rounded  cursor-pointer"
                                  onClick={() => {
                                    const newLevels = values.levels.filter(
                                      (_, i) => i !== index
                                    );
                                    setFieldValue("levels", newLevels);
                                  }}>
                                  <span>{level}</span>
                                  <i
                                    className="pointer"
                                    class="bi bi-x-circle"></i>
                                </li>
                              ))}
                            </ul>
                            <div
                              className="cursor-pointer bg-light text-primary rounded text-center mt-2 ms-2 px-3 py-2 d-flex align-items-center"
                              onClick={() => {
                                if (
                                  values.levelInput.trim() !== "" &&
                                  !values.levels.includes(
                                    values.levelInput.trim()
                                  )
                                ) {
                                  // Only add if the input is not empty and the level is not already in the array
                                  const newLevels = [
                                    ...values.levels,
                                    values.levelInput.trim(),
                                  ];
                                  setFieldValue("levels", newLevels);
                                  setFieldValue("levelInput", ""); // Clear the input after adding
                                }
                              }}>
                              <i class="bi bi-plus-circle-fill"></i>
                            </div>
                            {touched.levels && errors.levels && (
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp">
                                {errors.levels}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="image"
                            className="form-label">
                            Community Image
                          </label>
                          <input
                            className={`form-control ${
                              touched.image
                                ? errors.image
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            type="file"
                            id="image"
                            name="image"
                            onChange={(e) => {
                              setFieldValue("image", e.currentTarget.files[0]);
                            }}
                          />
                          {touched.image && errors.image && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.image}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <button
                          id={"submit-community"}
                          type="submit"
                          className="btn btn-primary me-1"
                          disabled={isSubmitting}>
                          {isSubmitting ? <Loader /> : "Submit"}
                        </button>
                        <button
                          onClick={() => navigate("/all-communities")}
                          type="button"
                          className="btn btn-danger light">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCommunity;
