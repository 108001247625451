// admin-api/dashboard
import axiosInstance from "../services/AxiosInstance";
export async function getDashboardData() {
  return axiosInstance
    .get("admin-api/dashboard")
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching dashboard data:", error);
      throw error;
    });
}
