import React from "react";
import { Row, Col, Card, ProgressBar, Button } from "react-bootstrap";

import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

import jsPDF from "jspdf";
import "jspdf-autotable";
//Import Components
import { BgCard } from "../../elements/CardDesign";
import { useQuery } from "@tanstack/react-query";
import { getDashboardData } from "../../../API/GetDashboardData";
import Swal from "sweetalert2";

// import DualArea from "../charts/Chartjs/dualArea";

//Charts
const DonughtChart = loadable(() =>
  pMinDelay(import("../../elements/dashboard/DonughtChart"), 500)
);
const DualArea = loadable(() =>
  pMinDelay(import("../charts/Chartjs/dualArea"), 500)
);

const Home = () => {
  const {
    isLoading,
    data: dashboard,
    isError,
    error,
  } = useQuery({
    queryKey: ["DashboardData"],
    queryFn: () => getDashboardData(),
  });

  // Generateing report

  const generatePDF = (data) => {
    const doc = new jsPDF();

    // Add a title
    doc.setFontSize(24);
    doc.text("Report", 14, 22);

    // Adding some data directly
    doc.setFontSize(18);
    doc.text("Overall Statistics", 14, 40);
    doc.setFontSize(11);
    doc.text(`Total Communities: ${data.totalCommunities}`, 14, 52);
    doc.text(`Total Users: ${data.totalUsers}`, 14, 62);
    doc.text(`Total Income: $${data.totalIncome}`, 14, 72);
    doc.text(`Total Courses: ${data.totalCourses}`, 14, 82);

    // Generate tables from data
    doc.text("Top Communities Enrollments:", 14, 100);
    doc.autoTable({
      startY: 110,
      theme: "striped",
      head: [["Community", "Enrollments"]],
      body: Object.entries(data.topCommunityEnrolls).map(([name, count]) => [
        name,
        count,
      ]),
    });
    doc.text("Top Communities Income:", 14, doc.lastAutoTable.finalY + 20);

    doc.autoTable({
      startY: doc.lastAutoTable.finalY + 30,
      theme: "grid",
      head: [["Community", "Income"]],
      body: Object.entries(data.topCommunityIncome).map(([name, income]) => [
        name,
        income,
      ]),
    });

    // Save the PDF
    doc.save("report.pdf");
  };
  const bgCarddBlog = isLoading
    ? []
    : [
        {
          title: "Community",
          number: dashboard?.totalCommunities,
          icon: <i className="la la-graduation-cap" />,
          color: "primary",
          maxCapacity: 20000,
          unit: "Communities",
        },
        {
          title: "Users",
          number: dashboard?.totalUsers,
          icon: <i className="la la-users" />,
          color: "secondary",
          maxCapacity: 20000,
          unit: "Users",
        },
        {
          title: "Courses",
          number: dashboard?.totalCourses,
          icon: <i className="la la-dollar" />,
          color: "warning",
          maxCapacity: 2000,
          unit: "Courses",
        },
        {
          title: "Income",
          number: dashboard?.totalIncome,
          icon: <i class="la la-hdd"></i>,
          color: "danger",
          maxCapacity: 4000000,
          unit: "EGP",
        },
      ];

  if (isError) {
    error.response.data.error === "In valid email or password" &&
      error.response.status === 403 &&
      Swal.fire({
        title: "Opps",
        text: "You must login again!",
        icon: "warning",
        confirmButtonColor: "#df0d0d",
        confirmButtonText: "Navigate to Login",
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem("userDetails");
          localStorage.removeItem("token");
          // navigate("/login");
          window.location.reload();
        }
      });
    return;
  }
  if (
    (isError &&
      error.response.data.error !== "In valid email or password" &&
      error.response.status !== 403) 
  ) {
    return (
      <Row>
        {" "}
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          Something went wrong
        </div>
      </Row>
    );
  }
  if (isLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            class="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">Loading...</span>
        </div>
      </Row>
    );
  }

  const HighestCommunities = dashboard?.topCommunityEnrolls;
  const HighestIncome = dashboard?.topCommunityIncome;

  let HighestCommunitiesKeys = [];
  let HighestCommunitiesValues = [];
  let HighestIncomeValues = [];
  let HighestIncomeKeys = [];
  let RestCommunities = 0;
  let RestIncome = 0;

  let CommunityProgress = dashboard?.communityProgress;
  let IncomeProgress = dashboard?.incomeProgress;
  let StudentProgress = dashboard?.studentProgress;

  HighestCommunitiesKeys = !isLoading && Object.keys(HighestCommunities);
  HighestCommunitiesValues = !isLoading && Object.values(HighestCommunities);

  HighestIncomeKeys = !isLoading && Object.keys(HighestIncome);
  HighestIncomeValues = !isLoading && Object.values(HighestIncome);
  RestCommunities =
    !isLoading &&
    dashboard?.totalUsers - HighestCommunitiesValues.reduce((a, b) => a + b, 0);

  RestIncome =
    !isLoading &&
    dashboard?.totalIncome - HighestIncomeValues.reduce((a, b) => a + b, 0);

  return (
    <>
      <Row>
        {bgCarddBlog.map((item, index) => (
          <Col
            xl={"3"}
            xxl={"3"}
            sm={"6"}
            key={index}>
            <div className={`widget-stat card bg-${item.color}`}>
              <div className="card-body">
                <BgCard
                  title={item.title}
                  number={
                    item.title === "Income" ? item.number + " EGP" : item.number
                  }
                  percent={
                    ((item.number / item.maxCapacity) * 100).toFixed(2) + "%"
                  }
                  color={item.color}
                  icon={item.icon}
                  maxCapacity={item.maxCapacity}
                  unit={item.unit}
                />
              </div>
            </div>
          </Col>
        ))}
        <Col
          xl={"6"}
          xxl={"6"}
          sm={"6"}>
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Highest Communities Enrollment</h3>
            </div>
            <div className="card-body d-flex justify-content-center flex-wrap xl:justify-content-center  gap-4">
              <DonughtChart
                data={HighestCommunitiesKeys}
                values={HighestCommunitiesValues}
                total={RestCommunities}
              />
              <div className="doughnut-chart__content col-6 d-flex flex-column gap-4">
                <h3>Top 3 Community</h3>

                <div className="d-flex justify-content-between gap-3">
                  <div className="d-flex flex-column gap-2 col-6">
                    <div className="text-center">
                      <h6>{HighestCommunitiesKeys[0]}</h6>
                      <div className="d-flex align-items-center gap-2">
                        <div className="">
                          {(
                            (HighestCommunitiesValues[0] /
                              dashboard?.totalUsers) *
                            100
                          ).toFixed(1)}
                          %
                        </div>
                        <ProgressBar
                          className="flex-grow-1"
                          now={
                            (HighestCommunitiesValues[0] /
                              dashboard?.totalUsers) *
                            100
                          }
                          variant="warning"
                        />
                      </div>
                    </div>
                    <div className="text-center">
                      <h6>{HighestCommunitiesKeys[1]}</h6>
                      <div className="d-flex align-items-center gap-2 ">
                        <div className="">
                          {(
                            (HighestCommunitiesValues[1] /
                              dashboard?.totalUsers) *
                            100
                          ).toFixed(1)}
                          %
                        </div>
                        <ProgressBar
                          className="flex-grow-1"
                          now={
                            (HighestCommunitiesValues[1] /
                              dashboard?.totalUsers) *
                            100
                          }
                          variant="danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-2 col-6">
                    <div className="text-center">
                      <h6>{HighestCommunitiesKeys[2]}</h6>
                      <div className="d-flex align-items-center gap-2">
                        <div className="">
                          {(
                            (HighestCommunitiesValues[2] /
                              dashboard?.totalUsers) *
                            100
                          ).toFixed(1)}
                          %
                        </div>
                        <ProgressBar
                          className="flex-grow-1"
                          now={
                            (HighestCommunitiesValues[2] /
                              dashboard?.totalUsers) *
                            100
                          }
                          variant="primary"
                        />
                      </div>
                    </div>
                    <div className="text-center">
                      <h6>Others</h6>
                      <div className="d-flex align-items-center gap-2">
                        <div className="">
                          {" "}
                          {(
                            (RestCommunities / dashboard?.totalUsers) *
                            100
                          ).toFixed(1)}
                          %
                        </div>
                        <ProgressBar
                          className="flex-grow-1"
                          now={(RestCommunities / dashboard?.totalUsers) * 100}
                          variant="secondary"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col
          xl={"6"}
          xxl={"6"}
          sm={"6"}>
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Highest Communities Income</h3>
            </div>
            <div className="card-body d-flex justify-content-center flex-wrap xl:justify-content-center  gap-4">
              <DonughtChart
                data={HighestIncomeKeys}
                values={HighestIncomeValues}
                total={RestIncome}
              />
              <div className="doughnut-chart__content col-6 d-flex flex-column gap-4">
                <h3>Top 3 Community</h3>

                <div className="d-flex justify-content-between gap-3">
                  <div className="d-flex flex-column gap-2 col-6">
                    <div className="text-center">
                      <h6>{HighestIncomeKeys[0]}</h6>
                      <div className="d-flex align-items-center gap-2">
                        <div className="">
                          {(
                            (HighestIncomeValues[0] / dashboard?.totalIncome) *
                            100
                          ).toFixed(1)}
                          %
                        </div>
                        <ProgressBar
                          className="flex-grow-1"
                          now={
                            (HighestIncomeValues[0] / dashboard?.totalIncome) *
                            100
                          }
                          variant="warning"
                        />
                      </div>
                    </div>
                    <div className="text-center">
                      <h6>{HighestIncomeKeys[1]}</h6>
                      <div className="d-flex align-items-center gap-2 ">
                        <div className="">
                          {(
                            (HighestIncomeValues[1] / dashboard?.totalIncome) *
                            100
                          ).toFixed(1)}
                          %
                        </div>
                        <ProgressBar
                          className="flex-grow-1"
                          now={
                            (HighestIncomeValues[1] / dashboard?.totalIncome) *
                            100
                          }
                          variant="danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-2 col-6">
                    <div className="text-center">
                      <h6>{HighestIncomeKeys[2]}</h6>
                      <div className="d-flex align-items-center gap-2">
                        <div className="">
                          {(
                            (HighestIncomeValues[2] / dashboard?.totalIncome) *
                            100
                          ).toFixed(1)}
                          %
                        </div>
                        <ProgressBar
                          className="flex-grow-1"
                          now={
                            (HighestIncomeValues[2] / dashboard?.totalIncome) *
                            100
                          }
                          variant="primary"
                        />
                      </div>
                    </div>
                    <div className="text-center">
                      <h6>Others</h6>
                      <div className="d-flex align-items-center gap-2">
                        <div className="">
                          {(
                            (RestIncome / dashboard?.totalIncome) *
                            100
                          ).toFixed(1)}
                          %
                        </div>
                        <ProgressBar
                          className="flex-grow-1"
                          now={(RestIncome / dashboard?.totalIncome) * 100}
                          variant="secondary"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          xxl={"12"}
          xl={"12"}
          sm={"12"}
          lg={"12"}>
          <div className="card h-max">
            <div className="card-header">
              <h4 className="card-title">Dual Area Chart</h4>
              <Card>
                <Button onClick={() => generatePDF(dashboard)}>
                  Generate Report
                </Button>
              </Card>
            </div>
            <div className="card-body ">
              <DualArea data={[CommunityProgress, StudentProgress]} />
            </div>
            <div className="card-body ">
              <DualArea
                data={[IncomeProgress]}
                isIncome
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default Home;
