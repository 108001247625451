// admin-api/dashboard

import axiosInstance from "../../services/AxiosInstance";

export async function GetCommunityDashboardData(id) {
  return axiosInstance
    .get(`admin-api/communities/${id}/community`)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching community dashboard data:", error);
      throw error;
    });
}
