export const MenuList = [
  //Dashboard
  {
    title: "Main Menu",
    classsChange: "menu-title",
    extraclass: "first",
  },
  //Dashboard
  {
    title: "Dashboard",
    // classsChange: "mm-collapse",
    iconStyle: <i className="bi bi-grid-fill"></i>,
    to: "dashboard",
    // content: [
    //   {
    //       title: "Dashboard 1",
    //       to: "dashboard",
    //   },
    //   {
    //     title: "Dashboard 2",
    //     to: "index-2",
    //   },
    //   {
    //     title: "Dashboard 3",
    //     to: "index-3",
    //   },
    // ],
  },

  //Student
  {
    title: "Admins",
    // classsChange: "mm-collapse",
    iconStyle: <i class="bi bi-person-fill-gear"></i>,
    to: "all-admins",
    // content: [
    //   {
    //     title: "All Students",
    //   },
    //   {
    //     title: "Add Students",
    //     to: "add-student",
    //   },
    //   {
    //     title: "Edit Students",
    //     to: "edit-student",
    //   },
    //   {
    //     title: "About Students",
    //     to: "about-student",
    //   },
    // ],
  },

  //Courses
  {
    title: "Teachers",
    // classsChange: "mm-collapse",
    iconStyle: <i class="fa-solid fa-person-chalkboard"></i>,
    to: "all-teachers",
    // content: [
    //   {
    //     title: "All Courses",
    //   },
    //   {
    //     title: "Add Courses",
    //     to: "add-courses",
    //   },
    //   {
    //     title: "Edit Courses",
    //     to: "edit-courses",
    //   },
    //   {
    //     title: "About Courses",
    //     to: "about-courses",
    //   },
    // ],
  },

  //My Profile
  {
    title: "Community",
    // classsChange: "mm-collapse",
    iconStyle: <i class="fa-solid fa-users"></i>,
    to: "all-communities",
    // content: [
    //   {
    //     title: "All Professor",
    //     to: "all-professors",
    //   },
    //   {
    //     title: "Add Professor",
    //     to: "add-professor",
    //   },
    //   {
    //     title: "Edit Professor",
    //     to: "edit-professor",
    //   },
    //   {
    //     title: "Professor Profile",
    //   },
    // ],
  },

  //Transactions
  {
    title: "Transactions",
    to: "all-transactions",
    iconStyle: <i class="fa-solid fa-money-bill-transfer"></i>,
  },
];
