import axiosInstance from "../../services/AxiosInstance";

export async function DeleteTeacher(id) {
  return axiosInstance
    .delete(`admin-api/teachers/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching teachers:", error);
      throw error; // Rethrow to be caught by React Query error handling
    });
}
